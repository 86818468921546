import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ForceGraph3D } from 'react-force-graph';
import * as THREE from 'three';
import { Box, TextField, Grid, Autocomplete, IconButton, Avatar, CircularProgress } from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import Tooltip from '../Tooltip';
import CardEmpty from '../card/CardEmpty';
import { configGraph3D } from '../../../assets/theme/g2Theme';
import { graphDefault } from '../../../redux/store';

function Graph3D(props) {
    const { infoChart } = props;

    const ref = useRef(null);
    const { t } = useTranslation();

    const graphColor = {
        institution: '#FA1800',
        campus: '#9601FB',
        faculty: '#ff6600',
        uab: '#54D62C',
        person: '#00796B',
        author: '#00796B',
        topic: '#CC2F69',
        all_all: '#94B43B',
        direct_university: '#00796B',
        direct_general: '#CFCFCF',
        indirect_university: '#9F1B48',
        indirect_general: '#CFCFCF'
    };

    const optionsType = [
        { label: t('components.graphics.graph3D.labelRadial'), value: 'radialout' },
        { label: t('components.graphics.graph3D.labelRandom'), value: null }
    ];

    const optionsConnection = [
        { label: t('components.graphics.graph3D.labelDirect'), value: 'direct' },
        { label: t('components.graphics.graph3D.labelIndirect'), value: 'indirect' }
    ];

    const optionsCollaboration = [
        { label: t('components.graphics.graph3D.labelGeneral'), value: 'general' },
        { label: t('components.graphics.graph3D.labelUniversity'), value: 'university' }
    ];

    const [values, setValues] = useState({
        graph: graphDefault,
        type: optionsType[0],
        connection: optionsConnection[0],
        collaboration: optionsCollaboration[0],
        search: null,
        fullScreen: false
    });
    const [loading, setLoading] = useState(false);

    const setLabel = (text) => `
        <div style="
            background-color: #F4F4F4;
            padding: 5px 10px;
            border-radius: 4px;
            color: #2f2f2f;
            font-family: 'Ancizar sans', sans-serif;
            font-size: 14px;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
            margin: -5px;
        ">
            ${text}
        </div>
    `;

    const handleNodeDragEnd = (node) => {
        node.fx = node.x;
        node.fy = node.y;
        node.fz = node.z;
    };

    const handleType = (event, value) => {
        setValues({ ...values, type: value });
    };

    const handleConnection = (event, value) => {
        setLoading(true);
        filterData(value, values.collaboration, values.search);
    };

    const handleCollaboration = (event, value) => {
        setLoading(true);
        filterData(values.connection, value, values.search);
    };

    const handleSearch = (event, value) => {
        const node = infoChart.data.nodes.filter(item => item.id === value.value);
        if (node.length === 1) {
            onNodeClick(node[0], values)
        }
    };

    const onNodeClick = useCallback((node, values) => {
        setLoading(true);
        const distance = 80;
        const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);
        ref.current.cameraPosition({ x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }, node, 3000);
        setValues(prevState => ({ ...prevState, search: node }));
        filterData(values.connection, values.collaboration, node.collaboration === 'all' ? null : node);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const nodeThreeObject = useCallback((node) => {
        const type = node.type;
        const connection = node.connection;
        const collaboration = node.collaboration;
        const color = graphColor[type === 'person' ? `${connection}_${collaboration}` || 'person' : type];
        let group = new THREE.Group();
        if (type === 'person' || type === 'author') {
            const bodyGeometry = new THREE.SphereGeometry(1.5, 64, 64);
            const bodyMaterial = new THREE.MeshStandardMaterial({ color: color, roughness: 0.6, metalness: 0.2 });
            const body = new THREE.Mesh(bodyGeometry, bodyMaterial);
            const headGeometry = new THREE.SphereGeometry(1, 64, 64);
            const headMaterial = new THREE.MeshStandardMaterial({ color: color, roughness: 0.5, metalness: 0.2 });
            const head = new THREE.Mesh(headGeometry, headMaterial);
            const baseGeometry = new THREE.CylinderGeometry(1.5, 1.5, 1.5, 64);
            const baseMaterial = new THREE.MeshStandardMaterial({ color: color, roughness: 0.5, metalness: 0.2 });
            const base = new THREE.Mesh(baseGeometry, baseMaterial);
            head.position.y = 2.7;
            base.position.y = -0.8;
            group.add(body);
            group.add(head);
            group.add(base);
        } else if (type === 'institution') {
            const buildingGeometry = new THREE.BoxGeometry(3, 3, 3);
            const buildingMaterial = new THREE.MeshStandardMaterial({ color: color, roughness: 0.6, metalness: 0.3 });
            const building = new THREE.Mesh(buildingGeometry, buildingMaterial);
            group.add(building);
        } else if (type === 'campus') {
            const domeGeometry = new THREE.SphereGeometry(2, 32, 32);
            const domeMaterial = new THREE.MeshStandardMaterial({ color: color, roughness: 0.5, metalness: 0.3 });
            const dome = new THREE.Mesh(domeGeometry, domeMaterial);
            dome.position.y = 1;
            group.add(dome);
        } else if (type === 'faculty') {
            const towerGeometry = new THREE.CylinderGeometry(1.5, 1.5, 5, 32);
            const towerMaterial = new THREE.MeshStandardMaterial({ color: color, roughness: 0.5, metalness: 0.3 });
            const tower = new THREE.Mesh(towerGeometry, towerMaterial);
            group.add(tower);
        } else if (type === 'uab') {
            const columnGeometry = new THREE.CylinderGeometry(1, 1, 4, 32);
            const columnMaterial = new THREE.MeshStandardMaterial({ color: color, roughness: 0.5, metalness: 0.3 });
            const column = new THREE.Mesh(columnGeometry, columnMaterial);
            group.add(column);
        } else if (type === 'topic') {
            const bookGeometry = new THREE.BoxGeometry(2, 0.3, 3);
            const bookMaterial = new THREE.MeshStandardMaterial({ color: color, roughness: 0.8, metalness: 0.1 });
            const book = new THREE.Mesh(bookGeometry, bookMaterial);
            group.add(book);
        }
        group.scale.set(2, 2, 2);
        return group;
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, []);

    const filterData = (connection, collaboration, search) => {
        const nodesActive = new Set();
        const items = [connection.value, collaboration.value, 'all', 'direct', 'university'];
        const links = infoChart.data.links.filter(item =>
            (infoChart.filter ? items.includes(item.connection) : true) &&
            (infoChart.filter ? items.includes(item.collaboration) : true) &&
            (search ? (item.source.id === search.id || item.target.id === search.id) : true)
        );
        links.forEach(item => {
            nodesActive.add(item.source.id || item.source);
            nodesActive.add(item.target.id || item.target);
        });
        const nodes = infoChart.data.nodes.filter(item => nodesActive.has(item.id));
        setValues(prevState => ({
            ...prevState,
            graph: { nodes: nodes, links: links, options: infoChart.data.options },
            connection: connection,
            collaboration: collaboration
        }));
        setLoading(false);
    };

    const handleFullScreen = () => {
        const graphContainer = document.getElementById('graph-container');
        if (graphContainer && !values.fullScreen) {
            setValues({ ...values, fullScreen: true });
            graphContainer.style.position = 'fixed';
            graphContainer.style.top = '0';
            graphContainer.style.left = '0';
            graphContainer.style.width = '100%';
            graphContainer.style.height = '100%';
            graphContainer.style.zIndex = '1100';
        } else {
            setValues({ ...values, fullScreen: false });
            graphContainer.style.position = '';
            graphContainer.style.top = '';
            graphContainer.style.left = '';
            graphContainer.style.width = '';
            graphContainer.style.height = '';
            graphContainer.style.zIndex = '';
        }
    };

    useEffect(() => {
        if (infoChart.filter) {
            filterData(optionsConnection[0], optionsCollaboration[0], values.search);
        } else {
            setValues(prevState => ({ ...prevState, graph: infoChart.data }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoChart.data]);

    return (
        <Box id='graph-container' sx={{ bgcolor: 'white', p: 2 }}>
            <Grid container spacing={3} sx={{ mb: 1 }}>
                <Grid item xs={12} md={infoChart.filter ? 2.75 : 5.5}>
                    <Tooltip title={t('components.graphics.graph3D.typeTooltip')}>
                        <Autocomplete
                            fullWidth
                            size='small'
                            disableClearable
                            onChange={handleType}
                            value={values.type}
                            options={optionsType}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                                <TextField {...params} label={t('components.graphics.graph3D.type')} />
                            )}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} md={2.75} sx={{ display: infoChart.filter ? 'inline-block' : 'none' }}>
                    <Tooltip title={t('components.graphics.graph3D.connectionTooltip')} placement='left'>
                        <Autocomplete
                            fullWidth
                            size='small'
                            disableClearable
                            onChange={handleConnection}
                            value={values.connection}
                            options={optionsConnection}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                                <TextField {...params} label={t('components.graphics.graph3D.connection')} />
                            )}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} md={2.75} sx={{ display: infoChart.filter ? 'inline-block' : 'none' }}>
                    <Tooltip title={t('components.graphics.graph3D.collaborationTooltip')} placement='left'>
                        <Autocomplete
                            fullWidth
                            size='small'
                            disableClearable
                            onChange={handleCollaboration}
                            value={values.collaboration}
                            options={optionsCollaboration}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => (
                                <TextField {...params} label={t('components.graphics.graph3D.collaboration')} />
                            )}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} md={infoChart.filter ? 2.75 : 5.5}>
                    <Tooltip title={t('components.graphics.graph3D.searchTooltip')}>
                        <Autocomplete
                            fullWidth
                            size='small'
                            disableClearable
                            onChange={handleSearch}
                            value={values.search}
                            options={infoChart.data.options.sort((a, b) => {
                                const numA = parseInt(a.group.split('.')[0]);
                                const numB = parseInt(b.group.split('.')[0]);
                                return numA - numB;
                            })}
                            isOptionEqualToValue={(option, value) => option.value === value.id}
                            groupBy={(option) => option.group}
                            renderInput={(params) => (
                                <TextField {...params} label={t('components.graphics.graph3D.search')} />
                            )}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} md={1}>
                    <Tooltip title={t('components.graphics.graph3D.screenAll')}>
                        <IconButton onClick={handleFullScreen} >
                            <Avatar
                                sx={{
                                    width: 30,
                                    height: 30,
                                    bgcolor: theme => theme.palette.primary.main,
                                    fontSize: 16,
                                }}
                            >
                                {values.fullScreen ? <FullscreenExit /> : <Fullscreen />}
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid >
            {
                infoChart.data.nodes.length === 0 ? (
                    <CardEmpty />
                ) : (loading ? (
                        <CircularProgress size="5rem" sx={{ mt: 18 }} />
                    ) : (
                        <ForceGraph3D
                            ref={ref}
                            width={values.fullScreen ? window.innerWidth - 50 : window.innerWidth - 180}
                            height={values.fullScreen ? window.innerHeight - 100 : 450}
                            backgroundColor={configGraph3D.backgroundColor}
                            dagMode={values.type.value}
                            graphData={values.graph}
                            nodeLabel={(node) => setLabel(node.label)}
                            linkLabel={(link) => setLabel(`${link.source.label} - ${link.target.label}: ${link.value}`)}
                            onNodeClick={(node) => onNodeClick(node, values)}
                            nodeThreeObject={nodeThreeObject}
                            linkMaterial={() => new THREE.MeshBasicMaterial({
                                color: '#F87A29',
                                opacity: 0.2,
                                transparent: true
                            })}
                            linkWidth={link => link.value}
                            onNodeDragEnd={handleNodeDragEnd}
                        />
                    )
                )
            }
        </Box >
    );
}
Graph3D.propTypes = {
    infoChart: PropTypes.shape({
        data: PropTypes.object,
        filter: PropTypes.bool
    })
};
export default Graph3D;