import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Grid, Box, useMediaQuery } from '@mui/material';
import Search from '../search/Search';
import logoWhite from '../../../assets/images/horus/logoHorus.png';
import logoBlack from '../../../assets/images/horus/logoHorusBlack.png';

function InvAppbar(props) {
    const { hierarchy, params, value } = props;
    const [logo, setLogo] = useState(logoWhite);

    const isHome = Object.keys(params).length === 0;
    const isXs = useMediaQuery((theme) => theme.breakpoints.up('xs'));
    const isSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const handleScroll = () => {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        if (!isHome) {
            if (isMd && (scrollTop >= 250)) {
                setLogo(logoBlack);
            } else if (isSm && (scrollTop >= 500)) {
                setLogo(logoBlack);
            } else if (isXs && (scrollTop >= 600)) {
                setLogo(logoBlack);
            } else {
                setLogo(logoWhite);
            }
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <AppBar
            position='absolute'
            sx={{
                zIndex: 0,
                boxShadow: 0,
                bgcolor: 'transparent',
                mt: isHome ? 28 : { xs: 9, sm: 15, md: 17 }
            }}
        >
            <Toolbar>
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                >
                    {!isHome && (
                        <Grid
                            item sm={12} md={1.5}
                            sx={{ textAlign: { xs: 'center', md: 'right' }, mr: 2 }}
                        >
                            <Link to='/'>
                                <Box
                                    component='img'
                                    alt='Logo Horus'
                                    sx={{ height: 40 }}
                                    src={logo}
                                />
                            </Link>
                        </Grid>
                    )}
                    <Grid
                        item xs={12} sm={9} md={5}
                        sx={{ mt: { xs: 1.5, md: 0 } }}
                    >
                        <Search hierarchy={hierarchy} params={params} value={value} />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar >
    )
}
InvAppbar.propTypes = {
    hierarchy: PropTypes.string,
    params: PropTypes.object,
    value: PropTypes.string
};
export default InvAppbar;