import { LocationCityTwoTone, BusinessTwoTone, HomeWorkTwoTone, HomeTwoTone, BadgeTwoTone, AssessmentTwoTone } from '@mui/icons-material';

export const URL_FRO = process.env.REACT_APP_URL_FRO
export const URL_API_INV = process.env.REACT_APP_URL_API_INV;
export const URL_API_EXT = process.env.REACT_APP_URL_API_EXT;
export const URL_API_GIP = process.env.REACT_APP_URL_API_GIP;

export const ACT_DEP = {
    INV: process.env.REACT_APP_ACT_INV === 'true' ? true : false,
    EXT: process.env.REACT_APP_ACT_EXT === 'true' ? true : false,
    AUT: process.env.REACT_APP_ACT_AUT === 'true' ? true : false,
    REP: process.env.REACT_APP_ACT_REP === 'true' ? true : false,
    FEE: process.env.REACT_APP_ACT_FEE === 'true' ? true : false
}

export const constants = {
    RESOURCES: {
        // INVESTIGATION
        'General': 'general',
        'Publicaciones': 'products',
        'Investigadores': 'researchers',
        'Grupos': 'groups',
        'Proyectos': 'projects',
        'Temáticas y Areas de trabajo': 'areas',
        'Lista de productos': 'productsList',
        // EXTENSION
        'Información General': 'general',
        // 'Proyectos': 'projects',
        'Oportunidades': 'opportunities',
        'Actividades': 'activities',
        'Perfil de profesor': 'author'
    },
    // COLLECTIONS 
    COLLECTION_TOT_GENERAL_METRICS: 'tot_general_metrics',
    COLLECTION_TOT_PRODUCTS_METRICS: 'tot_products_metrics',
    COLLECTION_TOT_RESEARCHERS_METRICS: 'tot_researchers_metrics',
    COLLECTION_TOT_GROUPS_METRICS: 'tot_groups_metrics',
    COLLECTION_TOT_PROJECTS_METRICS: 'tot_projects_metrics',
    COLLECTION_TOT_AREAS_METRICS: 'tot_areas_metrics',
    COLLECTION_TOT_GENDER_METRICS: 'tot_gender_metrics',
    // CORRECTIONS FIELDS
    // CORRECTIONS_ID_FIELD: '_id',
    // CORRECTIONS_PUBLICATION_ID_FIELD: 'publicationId',
    // CORRECTIONS_CORRECTION_FIELD: 'corrections',
    // CORRECTIONS_PUBLICATION_FIELD: 'publication',
    // CORRECTIONS_PUBLICATION_BACKUP_FIELD: 'publicationBackup',
    // CORRECTIONS_PROVIDER_FIELD: 'provider',
    // CORRECTIONS_EXECUTOR_FIELD: 'executor',
    // CORRECTIONS_CATEGORY_FIELD: 'category',
    // CORRECTIONS_ROOT_FIELD: 'root',
    // CORRECTIONS_REPORT_FIELD: 'report',
    // CORRECTIONS_CHANGES_FIELD: 'changes',
    // CORRECTIONS_CHANGES_PRE_FIELD: 'previous',
    // CORRECTIONS_CHANGES_NEW_FIELD: 'new',
    // CORRECTIONS_START_FIELD: 'startDate',
    // CORRECTIONS_END_FIELD: 'endDate',
    // CORRECTIONS_STATE_FIELD: 'state',
    // FEEDBACK FIELDS
    // FEEDBACK_ID_FIELD: '_id',
    // FEEDBACK_USER_FIELD: 'user',
    // FEEDBACK_CODE_FIELD: 'code',
    // FEEDBACK_NO_USER_FIELD: 'noUser',
    // FEEDBACK_FORM_FIELD: 'form',
    // FEEDBACK_DATE_FIELD: 'date',
    // USABILITY FIELDS
    // USABILITY_ID_FIELD: '_id',
    // USABILITY_DATE_FIELD: 'date',
    // USABILITY_INFO_FIELD: 'infoClient',
    // USABILITY_TRACE_FIELD: 'trace',
    // USABILITY_RECORD_FIELD: 'record',
    // AUTH FIELDS
    // AUTH_ID_FIELD: '_id',
    // AUTH_USER_FIELD: 'user',
    // AUTH_PASS_FIELD: 'password',
    // AUTH_NAME_FIELD: 'names',
    // AUTH_SURNAME_FIELD: 'surnames',
    // AUTH_DEPENDENCE_FIELD: 'dependence',
    // NEWS FIELD
    // NEWS_ID_FIELD: '_id',
    // NEWS_TITLE_FIELD: 'title',
    // NEWS_DESCRIPTION_FIELD: 'description',
    // NEWS_IMAGE_FIELD: 'image',
    // NEWS_TYPE_FIELD: 'type',        
    // AUTHORS FIELDS
    AUTHOR_ID_FIELD: '_id',
    AUTHOR_ISIN_FIELD: 'is-in',
    AUTHOR_NAME_FIELD: 'dc:name',
    AUTHOR_INSTITUTION_FIELD: 'dc:institution',
    AUTHOR_CAMPUS_FIELD: 'dc:campus',
    AUTHOR_FACULTY_FIELD: 'dc:faculty',
    AUTHOR_UAB_FIELD: 'dc:department',
    AUTHOR_GENDER_FIELD: 'dc:gender',
    AUTHOR_STATE_FIELD: 'dc:state',
    AUTHOR_IMAGE_FIELD: 'dc:image',
    AUTHOR_CONTRACT_FIELD: 'dc:contract',
    AUTHOR_POSITION_FIELD: 'dc:position',
    AUTHOR_EMAIL_FIELD: 'dc:email',
    AUTHOR_URL_RG_FIELD: 'dc:url-rg',
    AUTHOR_URL_GS_FIELD: 'dc:url-gs',
    AUTHOR_URL_WS_FIELD: 'dc:url-wos',
    AUTHOR_URL_CVLAC_FIELD: 'dc:url-cvlac',
    AUTHOR_URL_ORCID_FIELD: 'dc:url-orcid',
    AUTHOR_H_INDEX_SCOPUS_FIELD: 'dc:h-index:scopus',
    AUTHOR_H_INDEX_GS_FIELD: 'dc:h-index:gs',
    AUTHOR_PUBLICATIONS_FIELD: 'dc:publications',
    AUTHOR_GROUPS_FIELD: 'dc:groups',
    // PUBLICATIONS FIELDS
    PUBLICATION_ID_FIELD: '_id',
    PUBLICATION_TITLE_FIELD: 'dc:title',
    PUBLICATION_ENGLISH_TITLE_FIELD: 'dc:title:eng',
    PUBLICATION_SPANISH_TITLE_FIELD: 'dc:title:spa',
    PUBLICATION_ABSTRACT_FIELD: 'dc:description',
    PUBLICATION_ENGLISH_ABSTRACT_FIELD: 'dc:description:eng',
    PUBLICATION_SPANISH_ABSTRACT_FIELD: 'dc:description:spa',
    PUBLICATION_PUBNAME_FIELD: 'dc:source:publicationName',
    PUBLICATION_PUBNAME_ORIGINAL_FIELD: 'dc:source:publicationName:ORIGINAL',
    PUBLICATION_PUBNAME_NORMALIZED_FIELD: 'dc:source:publicationName:NORMALIZED',
    PUBLICATION_PUBNAME_SCORE_FIELD: 'dc:source:publicationName:SCORE',
    PUBLICATION_LANGUAGE_FIELD: 'dc:language',
    PUBLICATION_PUBLISHER_FIELD: 'dc:publisher',
    PUBLICATION_ENGLISH_TOPICS_FIELD: 'dc:subject:topics:eng',
    PUBLICATION_SPANISH_TOPICS_FIELD: 'dc:subject:topics:spa',
    PUBLICATION_ENGLISH_SUBJECTS_FIELD: 'dc:subject:subjects:eng',
    PUBLICATION_SPANISH_SUBJECTS_FIELD: 'dc:subject:subjects:spa',
    PUBLICATION_ENGLISH_KEYWORDS_FIELD: 'dc:subject:keywords:eng',
    PUBLICATION_SPANISH_KEYWORDS_FIELD: 'dc:subject:keywords:spa',
    PUBLICATION_DOI_FIELD: 'dc:doi',
    PUBLICATION_DATE_FIELD: 'dc:date',
    PUBLICATION_DATE_END_FIELD: 'dc:date:end',
    PUBLICATION_SOURCE_FIELD: 'dc:source',
    PUBLICATION_SOURCE_TYPE_FIELD: 'dc:source:type',
    PUBLICATION_CITATION_FIELD: 'dc:source:citation',
    PUBLICATION_AUTHORS_FIELD: 'dc:contributor:author',
    PUBLICATION_AFFILIATION_FIELD: 'dc:affiliation',
    PUBLICATION_AFFIL_NAME_FIELD: 'dc:affiliation:affilname',
    PUBLICATION_AFFIL_CITY_FIELD: 'dc:affiliation:city',
    PUBLICATION_AFFIL_COUNTRY_FIELD: 'dc:affiliation:country',
    PUBLICATION_URL_FIELD: 'dc:source:url',
    PUBLICATION_ISSN_FIELD: 'dc:source:issn',
    PUBLICATION_ISBN_FIELD: 'dc:source:isbn',
    PUBLICATION_VOLUME_FIELD: 'dc:source:volume',
    PUBLICATION_RANGE_FIELD: 'dc:source:pageRange',
    PUBLICATION_RANGE_START_FIELD: 'dc:source:pageRange:start',
    PUBLICATION_RANGE_END_FIELD: 'dc:source:pageRange:end',
    PUBLICATION_THESAURUS_FIELD: 'dc:thesaurus',
    PUBLICATION_ORIGINAL_SUBFIELD: 'ORIGINAL',
    PUBLICATION_NORMALIZED_SUBFIELD: 'NORMALIZED',
    PUBLICATION_SCORE_SUBFIELD: 'SCORE',
    PUBLICATION_THESIS_TYPES: ['Pregrado', 'Especialización', 'Maestría', 'Doctorado'],
    // REPORTS FIELD
    PROJECT_STATE_FIELD: 'dc:state:actual',
    // GROUPS FIELDS
    GROUP_ID_FIELD: '_id',
    GROUP_NAME_FIELD: 'dc:name',
    GROUP_EMAIL_FIELD: 'dc:email',
    GROUP_STATE_FIELD: 'dc:state',
    GROUP_URL_FIELD: 'dc:url',
    GROUP_DATE_FIELD: 'dc:date',
    GROUP_DESCRIPTION_FIELD: 'dc:description',
    GROUP_OCDE_FIELD: 'dc:ocde',
    GROUP_CATEGORIES_FIELD: 'dc:categories',
    GROUP_URL_GROUPLAC_FIELD: 'dc:url:grouplac',
    GROUP_AGENDA_FIELD: 'dc:agenda',
    GROUP_LINES_FIELD: 'dc:lines',
    GROUP_LEADER_FIELD: 'dc:leader',
    GROUP_MEMBERS_FIELD: 'dc:members',
    GROUP_COL_CODE_FIELD: 'dc:colciencias:code',
    GROUP_COL_CATEGORY_FIELD: 'dc:colciencias:category',
    GROUP_COL_STATE_FIELD: 'dc:colciencias:status',
    // DEPENDENCES FIELDS
    DEPENDENCE_PARENT_FIELD: 'dc:parent',
    DEPENDENCE_INSTITUTION_FIELD: 'dc:institution',
    DEPENDENCE_INSTITUTION_VALUE_FIELD: 'Universidad Nacional de Colombia',
    DEPENDENCE_CAMPUS_FIELD: 'dc:campus',
    DEPENDENCE_FACULTY_FIELD: 'dc:faculty',
    DEPENDENCE_UAB_FIELD: 'dc:department',
    DEPENDENCE_AUTHOR_FIELD: 'dc:author',
    DEPENDENCE_AUTHOR_INFO_FIELD: 'dc:author:info',
    DEPENDENCE_CHILDREN_FIELD: 'dc:children',
    DEPENDENCE_METRICS_FIELD: 'dc:metrics',
    DEPENDENCE_INFO_FIELD: {
        DEPENDENCE_INSTITUTION_FIELD: {
            name: 'Institución',
            icon: LocationCityTwoTone,
            infoLevel_en: ['campus', 'campuses'],
            infoLevel_es: ['sede', 'sedes'],
        },
        DEPENDENCE_CAMPUS_FIELD: {
            name: 'Sede',
            icon: BusinessTwoTone,
            infoLevel_en: ['faculty', 'faculties'],
            infoLevel_es: ['facultad', 'facultades'],
        },
        DEPENDENCE_FACULTY_FIELD: {
            name: 'Facultad',
            icon: HomeWorkTwoTone,
            infoLevel_en: ['uab', 'uab'],
            infoLevel_es: ['uab', 'uab'],
        },
        DEPENDENCE_UAB_FIELD: {
            name: 'Uab',
            icon: HomeTwoTone,
            infoLevel_en: ['teacher', 'teachers'],
            infoLevel_es: ['profesor', 'profesores'],
        },
        DEPENDENCE_AUTHOR_FIELD: {
            name: 'Profesor',
            icon: BadgeTwoTone,
            infoLevel_en: ['', ''],
            infoLevel_es: ['', ''],
        },
        general: {
            name: 'General',
            icon: AssessmentTwoTone,
            infoLevel_en: ['', ''],
            infoLevel_es: ['', ''],
        },
        // group: {
        //     name: 'Grupo',
        //     icon: Diversity3,
        //     infoLevel: ['', ''],
        // },
        // id: {
        //     name: 'Grupo',
        //     icon: Diversity3,
        //     infoLevel: ['', ''],
        // },
        // extension: {
        //     name: 'Extensión',
        //     icon: DashboardTwoTone,
        //     infoLevel: ['', ''],
        // },
        // curator: {
        //     name: 'Curaduría',
        //     icon: AppRegistrationTwoTone,
        //     infoLevel: ['', ''],
        // },
        // edit: {
        //     name: 'Editar publicación',
        //     icon: BorderColorTwoTone,
        //     infoLevel: ['', ''],
        // },
        // login: {
        //     name: 'Inicio de sesión',
        //     icon: Login,
        //     infoLevel: ['', ''],
        // },
    },
    // METRICS FIELDS
    METRIC_BOTH_FIELD: 'A',
    METRIC_FEMALE_FIELD: 'F',
    METRIC_MALE_FIELD: 'M',
    METRIC_VALUE_FIELD: 'value',
    METRIC_AUTHORS_FIELD: 'authors',
    METRIC_AUTHORS_VALUE_FIELD: 'authors_total',
    METRIC_PUBLICATIONS_FIELD: 'publications',
    METRIC_AUTHORS_LIST_FIELD: 'authors_list',
    METRIC_PROJECTS_AUTHORS_FIELD: 'authors_projects',
    METRIC_PRODUCTS_AUTHORS_FIELD: 'authors_products',
    METRIC_TYPE_LIST_FIELD: 'type_list',
    METRIC_PROJECTS_TYPE_FIELD: 'type_projects',
    METRIC_PRODUCTS_TYPE_FIELD: 'type_products',
    METRIC_PUBLICATIONS_LIST_FIELD: 'publications_list',
    METRIC_COURSES_FIELD: 'courses',
    METRIC_METRICS_FIELD: 'metrics',
    METRIC_CITATIONS_FIELD: 'citations',
    METRIC_THESIS_FIELD: 'thesis',
    METRIC_GROUPS_FIELD: 'groups',
    METRIC_PROJECTS_FIELD: 'projects',
    METRIC_PROJECTS_LIST_FIELD: 'projects_list',
    METRIC_AUTHORS_INFO_FIELD: 'authors_info',
    METRIC_GROUPS_INFO_FIELD: 'groups_info',
    METRIC_OCDE_FIELD: 'ocde',
    METRIC_AGENDA_FIELD: 'agenda',
    METRIC_GROUPS_LIST_INFO_FIELD: 'groups_list_info',
    METRIC_STATE_FIELD: 'state',
    METRIC_STATE_YEAR_FIELD: 'state_year',
    METRIC_APPROBATION_FIELD: 'approbation',
    METRIC_APPROBATION_YEAR_FIELD: 'approbation_year',
    METRIC_DURATION_FIELD: 'duration',
    METRIC_DURATION_PRORROGA_FIELD: 'duration_prorroga',
    METRIC_TYPOLOGY_FIELD: 'typology',
    METRIC_TYPOLOGY_YEAR_FIELD: 'typology_year',
    METRIC_PRODUCTION_FIELD: 'production',
    METRIC_YEAR_FIELD: 'year',
    METRIC_TYPE_FIELD: 'type',
    METRIC_TYPE_ALL_FIELD: 'all',
    METRIC_TYPE_JOURNALS_FIELD: 'journals',
    METRIC_TYPE_CONFERENCE_FIELD: 'conference',
    METRIC_TYPE_THESIS_FIELD: 'thesis',
    METRIC_TYPE_YEAR_FIELD: 'type_year',
    METRIC_SUBJECTS_FIELD: 'subjects',
    METRIC_TOPICS_FIELD: 'topics',
    METRIC_WORDCLOUD_FIELD: 'wordcloud',
    METRIC_COUNTRIES_FIELD: 'countries',
    METRIC_INSTITUTIONS_FIELD: 'institutions',
    METRIC_JOURNALS_FIELD: 'journals',
    METRIC_JOURNALS_CLASS_FIELD: 'journals_classification',
    METRIC_AGE_FIELD: 'age',
    METRIC_AVERAGE_FIELD: 'average',
    METRIC_RATE_FIELD: 'rate',
    METRIC_SCIMAGO_CLASS_FIELD: 'scimago_classification',
    METRIC_ACTIVE_FIELD: 'active',
    METRIC_GRAPH_FIELD: 'graph',
    METRIC_GRAPH_KEYWORDS_FIELD: 'graph_keywords',
    METRIC_LDA_FIELD: 'lda',
    METRIC_PERCENTAGE_PUBLISHING_FIELD: 'percentage_publishing',
    METRIC_GENDER_FIELD: 'gender',
    METRIC_GENDER_LEADERS_FIELD: 'gender-leaders',
    METRIC_GENDER_THESIS_FIELD: 'gender-thesis',
    METRIC_GENDER_TYPES_FIELD: 'gender-types',
    METRIC_YEARS_FIELD: 'years',
    METRIC_SUM_YEAR_FIELD: 'sumYear',
    METRIC_SUM_MONTH_FIELD: 'sumMonths',
    METRIC_SUM_SEMESTER_FIELD: 'sumSem',
    METRIC_COUNT_YEAR_FIELD: 'countYear',
    METRIC_COUNT_MONTH_FIELD: 'countMonths',
    METRIC_COUNT_SEMESTER_FIELD: 'countSem',
    METRIC_VALUE_YEAR_FIELD: 'valuesYear',
    METRIC_VALUE_MONTH_FIELD: 'valuesMonths',
    METRIC_VALUE_SEMESTER_FIELD: 'valuesSem',
    METRIC_COST_FIELD: 'cost',
    METRIC_ALL_VALUE_FIELD: 'all_value',
    METRIC_ALLIES_COUNT_FIELD: 'allies_count',
    METRIC_ALLIES_VALUE_FIELD: 'allies_value',
    METRIC_LOCATIONS_FIELD: 'locations',
    METRIC_COUNT_FIELD: 'count',
    METRIC_CLASSIFICATION_FIELD: 'classification',
    METRIC_TOP_FIELD: 'top_directors',
    METRIC_ORIGEN_FIELD: 'origen_propuesta',
    METRIC_TOP_LIST_FIELD: 'top',
    METRIC_SUBJECTS__FIELD: 'dc:subject:subjects:',
    METRIC_TOPICS__FIELD: 'dc:subject:topics:',
    METRIC_COUNTS_FIELD: 'counts',
    METRIC_ENROLLED_FIELD: 'enrolled',
    METRIC_TYPE_COUNT_FIELD: 'tipo_count',
    METRIC_MOD_INTENSITY_FIELD: 'submod_intensity',
    METRIC_MOD_ENROLLED_FIELD: 'submod_enrolled_count',
    METRIC_MOD_COUNT_FIELD: 'submod_count',
    // ORDER
    ORDER_TYPE: {
        'date': 'dc:date',
        'citations': 'dc:source:citation',
        'relevance': 'relevance'
    },
    // COMPLEMENT METRICS FIELDS
    GRAPHS_TYPE: {
        ARC: 'Arco polar',
        CHORD: 'Acorde',
        FORCE: 'Fuerza'
    },
    TRANSLATIONS: {
        'en': {
            'translation': {
                'header': {
                    'investigation': 'Research',
                    'extension': 'Extended projects',
                    'extensionFacultadIngenieria': 'Faculty of Engineering Extension',
                    'wiki': 'About',
                    'language': 'Language',
                    'services': 'Services',
                    'email': 'Email',
                    'sia': 'DINARA - SIA',
                    'libraries': 'Libraries',
                    'calls': 'Calls',
                    'identity': 'UNAL Identity',
                    'login': 'Login',
                    'reports': 'Reports',
                    'accessibility': 'Accessibility Panel',
                    'letterSize': 'Letter size',
                    'changeContrasts': 'Change Contrasts',
                    'invertColors': 'Invert colors',
                    'resetSettings': 'Reset settings',
                    'apply': 'Apply'
                },
                'footer': {
                    'legalRegime': 'Legal framework',
                    'humanTalent': 'Human resources',
                    'hiring': 'Contracting',
                    'jobVacancies': 'Job vacancies',
                    'accountability': 'Accountability',
                    'teachingCompetition': 'Teaching competition',
                    'virtualPayment': 'Virtual Payment',
                    'internalControl': 'Internal control',
                    'quality': 'Quality',
                    'notificationMailbox': 'Notification mailbox',
                    'institutionalEmail': 'Institutional email',
                    'siteMap': 'Site Map',
                    'socialNetworks': 'Social networks',
                    'faq': 'FAQ',
                    'complaintsClaims': 'Complaints and claims',
                    'onlineAttention': 'Online attention',
                    'survey': 'Survey',
                    'contactUs': 'Contact Us',
                    'statistics': 'Statistics',
                    'glossary': 'Glossary',
                    'contactWebsite': 'Contact website',
                    'contactInfo': 'Contact the administrator of this website',
                    'author': 'Some rights reserved.<br />Grupo de Investigación MIDAS<br />Dir: Ing. Elizabeth León Guzmán, Ph.D.<br />',
                    'wiki': 'About this website',
                    'update': 'Update'
                },
                'home': {
                    'description': 'Visualization of the <b>research capacity</b> and <b>scientific production</b> of the <b>National University of Colombia</b>.'
                },
                'components': {
                    'search': {
                        'SearchTopics': {
                            'name': 'Topics',
                            'placeholder': 'Search by topic',
                            'tooltip': 'Press to search',
                            'gateInfo': 'Press to change the search logic gate from',
                            'gateTo': 'to',
                            'consultingInfoSearch': 'Consulting information.'
                        },
                        'SearchDependences': {
                            'name': 'Dependencies and Authors',
                            'placeholder': 'Search by dependencies and authors',
                            'results': 'Search results',
                            'tooltip': 'Press to clear the search field'
                        }
                    },
                    'card': {
                        'cardAuthor': {
                            'email': 'Email',
                            'bonding': 'Bonding',
                            'tooltipInfo': ' from teacher',
                            'profiles': 'Academic Profiles',
                            'tooltipPlat': 'Go to the teacher\'s profile in ',
                            'hIndex': 'H-index',
                            'DOCENTE TIEMPO COMPLETO': ' Full-Time Faculty',
                            'DOCENTE DEDICAC. EXCLUSIVA': 'Exclusively Dedicated Faculty',
                            'DOCENTE CATEDRA 0,4': 'Adjunct Faculty (0.4 Full-Time Equivalent)',
                            'DOCENTE CATEDRA 0,5': 'Adjunct Faculty (0.5 Full-Time Equivalent)',
                            'DOCENTE CATEDRA 0,2': 'Adjunct Faculty (0.2 Full-Time Equivalent)',
                            'DOCENTE CATEDRA 0,3': 'Adjunct Faculty (0.3 Full-Time Equivalent)',
                            'DOCENTE MEDIO TIEMPO': 'Part-Time Faculty',
                            'DOCENTE CATEDRA 0,6': 'Adjunct Faculty (0.6 Full-Time Equivalent)',
                            'DOCENTE CATEDRA 0,7': 'Adjunct Faculty (0.7 Full-Time Equivalent)',
                            'DOCENTE CATEDRA 0,1': 'Adjunct Faculty (0.1 Full-Time Equivalent)',
                            'DOCENTE CATEDRA 0,0': 'Adjunct Faculty (0.0 Full-Time Equivalent)',
                            'BECARIO PREGRADO 3 HORAS': 'Undergraduate Fellow (3 hours)',
                            'ADMINISTRATIVO T. COMPLETO': 'Full-Time Administrative Staff',
                            'PROFESOR ASOCIADO': 'Associate Professor',
                            'PROFESOR TITULAR': 'Full Professor',
                            'PROFESOR AUXILIAR': 'Auxiliar Professor',
                            'PROFESOR ASISTENTE': 'Assistant Professor',
                            'INSTRUCTOR ASOCIADO': 'Associate Instructor',
                            'EXPERTO III': 'EXPERTO III',
                            'INSTRUCTOR ASISTENTE': 'Assistant Instructor',
                            '.': 'No reportado',
                            'NO VIGENTE - VICERRECTOR': 'NO VIGENTE - VICERRECTOR'
                        },
                        'cardEmpty': {
                            'empty': 'No results found'
                        },
                        'cardGraphics': {
                            'filterTime': 'Chart data changes with time filter',
                            'genderTooltip': 'Select an option to change the graph data based on gender',
                            'gender': 'Gender',
                            'typeTooltip': 'Select an option to change metrics regarding product type',
                            'type': 'Product type',
                            'accumulateTrueTooltip': 'Press to show accumulated data',
                            'accumulateTrue': 'Accumulated',
                            'accumulateFalseTooltip': 'Press to not show accumulated data',
                            'accumulateFalse': 'Not accumulated',
                            'wordCloudTypeTooltip': 'Select an option to change the type of word cloud',
                            'wordCloudType': 'Topics',
                            'wordCloudLanguageTooltip': 'Select an option to change the language',
                            'wordCloudLanguage': 'Change language',
                            'typeChart': 'Type of chart',
                            'typeChartTooltip': 'Select an option to change the chart type',
                            'downloadImage': 'Download image',
                            'downloadCsv': 'Download as CSV',
                            'print': 'Print',
                            'dialog': 'About the graph',
                            'dataTableTrueTooltip': 'View data table',
                            'dataTableFalseTooltip': 'Hide data table',
                            'export': 'Export',
                            'dialogClose': 'Close'
                        },
                        'cardGroup': {
                            'email': 'Email',
                            'date': 'Registration date',
                            'state': 'State',
                            'informationTooltip': ' of the group',
                            'colciencias': 'Colciencias Information',
                            'colcienciasCode': 'Code',
                            'colcienciasCategory': 'Category',
                            'colcienciasState': 'State',
                            'profiles': 'Academic Profiles',
                            'linkHermes': 'Link to Hermes',
                            'linkGrupLac': 'Link to GrupLAC',
                            'lines': 'Lines of research',
                            'leader': 'Leader information'
                        },
                        'cardNews': {
                            'title': 'HORUS News'
                        },
                        'cardProductsList': {
                            'searchContent': 'Search by content',
                            'authorTrue': 'Dependencies and authors',
                            'authorFalse': 'Directors',
                            'authorFalseVariant': 'Submodality',
                            'typeTrue': 'Product type',
                            'typeFalse': 'Type of modality',
                            'dateTrue': 'Date',
                            'dateFalse': 'Start date - End date',
                            'order': 'Sort by',
                            'orderDate': 'To order by date',
                            'orderCitations': 'Sort by citations',
                            'orderValue': 'Sort by value',
                            'orderRelevance': 'Sort by relevance',
                            'orderCitationsTrue': 'citations',
                            'orderCitationsFalse': 'value',
                            'orderCitationsFalseVariant': 'Number of participants',
                            'orderAsc': 'Ascending order',
                            'orderDsc': 'Descending order',
                            'download': 'Download products',
                            'labelRows': 'Rows per page',
                            'labelRowsOf': 'of',
                            'title': 'Title',
                            'abstractTrue': 'Abstract',
                            'abstractFalse': 'Contracting entity',
                            'abstractFalseVariant': 'Object of the contract',
                            'relevance': 'Relevance',
                            'languageTrue': 'Original language of the product',
                            'languageFalse': 'Original language of the product',
                            'languageFalseVariant': 'Hourly intensity',
                            'correctionsApplied': 'Fixes applied',
                            'correctionsPending': 'Pending fixes',
                            'linkProduct': 'Link to product',
                            'curatorTrue': 'See the product',
                            'curatorFalse': 'Correct the product',
                            'linkAuthor': 'Visit the teacher\'s profile'
                        }
                    },
                    'graphics': {
                        'choroplethWorMap': {
                            'collaborations': 'Collaborations'
                        },
                        'graph3D': {
                            'labelRadial': 'Radial',
                            'labelRandom': 'Random',
                            'labelDirect': 'Direct',
                            'labelIndirect': 'Indirect',
                            'labelGeneral': 'General',
                            'labelUniversity': 'University',
                            'typeTooltip': 'Select the type of graph you want to view',
                            'type': 'Graph type',
                            'searchTooltip': 'Enter or select the option you want to search in the network',
                            'search': 'Search in the network',
                            'defaultGraph': 'Press to see the default graph',
                            'screenAll': 'Press to view the graph in full screen',
                            'connectionTooltip': 'Select an option to change the network type',
                            'connection': 'Network type',
                            'collaborationTooltip': 'Select an option to change the type of collaboration',
                            'collaboration': 'Type of collaboration',
                        },
                        'Artículo de revista': 'Journal article',
                        'Estado del arte': 'State of the art',
                        'Tesis Pregrado': 'Undergraduate Thesis',
                        'Capítulo de libro': 'Book Chapter',
                        'Libro': 'Book',
                        'Tesis Maestría': 'Master\'s Thesis',
                        'Artículo de conferencia': 'Conference paper',
                        'Reporte tecnico': 'Technical report',
                        'Formato audiovisual': 'Audiovisual format',
                        'Colección científica': 'Scientific collection',
                        'Tesis Especialización': 'Specialization Thesis',
                        'Tesis Doctorado': 'Doctoral Thesis',
                        'Patente': 'Patent',
                        'Editorial': 'Editorial',
                        'Desarrollo de software': 'Software development',
                        'Proceso instrumental': 'Instrumental process',
                        'Datos': 'Data',
                        'Ponencias en eventos': 'Presentations at events',
                        'Posters presentados': 'Posters presented',
                        'Biografía': 'Biography',
                        'Preimpresión': 'Prepress',
                        'Proyecto': 'Project',
                        'Comentario': 'Comment',
                        'Errata': 'Erratum',
                        'Estudio observacional': 'Observational study',
                        'Estudio multicéntrico': 'Multicenter study',
                        'Apoyo a la investigación': 'Support for research',
                        'Reporte de caso': 'Case report',
                        'Estudio comparativo': 'Comparative study',
                        'Revisión sistemática': 'Systematic review',
                        'Internacional': 'International',
                        'Nacional': 'National',
                        'AND': 'Andorra',
                        'ARE': 'United Arab Emirates',
                        'AFG': 'Afghanistan',
                        'ATG': 'Old and bearded',
                        'AIA': 'Eel',
                        'ALB': 'Albania',
                        'ARM': 'Armenia',
                        'ANT': 'Netherlands Antilles',
                        'AGO': 'Angola',
                        'ATA': 'Antarctica',
                        'ARG': 'Argentina',
                        'ASM': 'American Samoa',
                        'AUT': 'Austria',
                        'AUS': 'Australia',
                        'ABW': 'Aruba',
                        'ALA': 'Aland Islands',
                        'AZE': 'Azerbaijan',
                        'BIH': 'Bosnia and Herzegovina',
                        'BRB': 'Barbados',
                        'BGD': 'Bangladesh',
                        'BEL': 'Belgium',
                        'BFA': 'Burkina Faso',
                        'BGR': 'Bulgaria',
                        'BHR': 'Bahrain',
                        'BDI': 'Burundi',
                        'BEN': 'Benin',
                        'BLM': 'San Bartolome',
                        'BMU': 'Bermuda',
                        'BRN': 'Brunei',
                        'BOL': 'Bolivia',
                        'BRA': 'Brazil',
                        'BHS': 'Bahamas',
                        'BTN': 'Bhutan',
                        'BVT': 'Bouvet Island',
                        'BWA': 'Botswana',
                        'BLR': 'Belarus',
                        'BLZ': 'Belize',
                        'CAN': 'Canada',
                        'CCK': 'Cocos Islands',
                        'CAF': 'Central African Republic',
                        'COG': 'Congo',
                        'CHE': 'Swiss',
                        'CIV': 'Ivory Coast',
                        'COK': 'Cook Islands',
                        'CHL': 'Chili',
                        'CMR': 'Cameroon',
                        'CHN': 'China',
                        'COL': 'Colombia',
                        'CRI': 'Costa Rica',
                        'CUB': 'Cuba',
                        'CPV': 'Cape Verde',
                        'CXR': 'Christmas Islands',
                        'CYP': 'Cyprus',
                        'CZE': 'Czech Republic',
                        'DEU': 'Germany',
                        'DJI': 'Djibouti',
                        'DNK': 'Denmark',
                        'DMA': 'Dominica',
                        'DOM': 'Dominican Republic',
                        'DZA': 'Algiers',
                        'ECU': 'Ecuador',
                        'EST': 'Estonia',
                        'EGY': 'Egypt',
                        'ESH': 'Occidental Sahara',
                        'ERI': 'Eritrea',
                        'ESP': 'Spain',
                        'ETH': 'Ethiopia',
                        'FIN': 'Finland',
                        'FJI': 'fiji',
                        'FLK': 'Falkland Islands',
                        'FSM': 'Micronesia',
                        'FRO': 'Faroe Islands',
                        'FRA': 'France',
                        'GAB': 'Gabon',
                        'GBR': 'United Kingdom',
                        'GRD': 'Grenade',
                        'GEO': 'Georgia',
                        'GUF': 'French Guiana',
                        'GGY': 'Guernsey',
                        'GHA': 'Ghana',
                        'GIB': 'Gibraltar',
                        'GRL': 'Greenland',
                        'GMB': 'Gambia',
                        'GIN': 'Guinea',
                        'GLP': 'Guadeloupe',
                        'GNQ': 'Equatorial Guinea',
                        'GRC': 'Greece',
                        'SGS': 'South Georgia and the South Sandwich Islands',
                        'GTM': 'Guatemala',
                        'GUM': 'guam',
                        'GNB': 'Guinea-Bissau',
                        'GUY': 'Guiana',
                        'HKG': 'Hong Kong',
                        'HMD': 'Heard and McDonald Islands',
                        'HND': 'Honduras',
                        'HRV': 'Croatia',
                        'HTI': 'Haiti',
                        'HUN': 'Hungary',
                        'IDN': 'Indonesia',
                        'IRL': 'Ireland',
                        'ISR': 'Israel',
                        'IMN': 'Isle of Man',
                        'IND': 'India',
                        'IOT': 'British Indian Ocean Territory',
                        'IRQ': 'Iraq',
                        'IRN': 'Iran',
                        'ISL': 'Iceland',
                        'ITA': 'Italy',
                        'JEY': 'sweater',
                        'JAM': 'Jamaica',
                        'JOR': 'Jordan',
                        'JPN': 'Japan',
                        'KEN': 'Kenya',
                        'KGZ': 'Kyrgyzstan',
                        'KHM': 'Cambodia',
                        'KIR': 'Kiribati',
                        'COM': 'Comoros',
                        'KNA': 'Saint Kitts and Nevis',
                        'PRK': 'North Korea',
                        'KOR': 'South Korea',
                        'KWT': 'Kuwait',
                        'CYM': 'Cayman Islands',
                        'KAZ': 'Kazakhstan',
                        'LAO': 'Laos',
                        'LBN': 'Lebanon',
                        'LCA': 'St. Lucia',
                        'LIE': 'Liechtenstein',
                        'LKA': 'Sri Lanka',
                        'LBR': 'Liberia',
                        'LSO': 'Lesotho',
                        'LTU': 'Lithuania',
                        'LUX': 'Luxembourg',
                        'LVA': 'Latvia',
                        'LBY': 'Libya',
                        'MAR': 'Morocco',
                        'MCO': 'Monaco',
                        'MDA': 'Moldova',
                        'MNE': 'Montenegro',
                        'MDG': 'Madagascar',
                        'MHL': 'Marshall Islands',
                        'MKD': 'Macedonia',
                        'MLI': 'Mali',
                        'MMR': 'Myanmar',
                        'MNG': 'Mongolia',
                        'MAC': 'Macau',
                        'MTQ': 'Martinique',
                        'MRT': 'Mauritania',
                        'MSR': 'Montserrat',
                        'MLT': 'malt',
                        'MUS': 'Mauricio',
                        'MDV': 'Maldives',
                        'MWI': 'Malawi',
                        'MEX': 'Mexico',
                        'MYS': 'Malaysia',
                        'MOZ': 'Mozambique',
                        'NAM': 'Namibia',
                        'NCL': 'New Caledonia',
                        'NER': 'Niger',
                        'NFK': 'Norwayfolk Islands',
                        'NGA': 'Nigeria',
                        'NIC': 'Nicaragua',
                        'NLD': 'Netherlands',
                        'NOR': 'Norway',
                        'NPL': 'Nepal',
                        'NRU': 'Nauru',
                        'NIU': 'Niue',
                        'NZL': 'New Zealand',
                        'OMN': 'Oman',
                        'PAN': 'Panama',
                        'PER': 'Peru',
                        'PYF': 'French Polynesia',
                        'PNG': 'Papua New Guinea',
                        'PHL': 'Philippines',
                        'PAK': 'Pakistan',
                        'POL': 'Poland',
                        'SPM': 'Saint Pierre and Miquelon',
                        'PCN': 'Pitcairn Islands',
                        'PRI': 'Puerto Rico',
                        'PSE': 'Palestine',
                        'PRT': 'Portugal',
                        'PLW': 'Palau Islands',
                        'PRY': 'Paraguay',
                        'QAT': 'Qatar',
                        'REU': 'Meeting',
                        'ROU': 'Romania',
                        'SRB': 'Serbia and Montenegro',
                        'RUS': 'Russia',
                        'RWA': 'Rwanda',
                        'SAU': 'Saudi Arabia',
                        'SLB': 'Solomon Islands',
                        'SYC': 'Seychelles',
                        'SDN': 'Sudan',
                        'SWE': 'Sweden',
                        'SGP': 'Singapore',
                        'SHN': 'st. Helen',
                        'SVN': 'Slovenia',
                        'SJM': 'Svalbard and Jan Mayen Islands',
                        'SVK': 'Slovakia',
                        'SLE': 'Sierra Leone',
                        'SMR': 'San Marino',
                        'SEN': 'Senegal',
                        'SOM': 'Somalia',
                        'SUR': 'Surinam',
                        'STP': 'Sao Tome and Principe',
                        'SLV': 'The Savior',
                        'SYR': 'Syria',
                        'SWZ': 'Swaziland',
                        'TCA': 'Turks and Caicos Islands',
                        'TCD': 'Chad',
                        'ATF': 'French Southern Territories',
                        'TGO': 'Togo',
                        'THA': 'Thailand',
                        'TZA': 'Tanzania',
                        'TJK': 'Tajikistan',
                        'TKL': 'Tokelau',
                        'TLS': 'Timor-Leste',
                        'TKM': 'Turkmenistan',
                        'TUN': 'Tunisia',
                        'TON': 'Tonga',
                        'TUR': 'Türkiye',
                        'TTO': 'Trinidad and Tobago',
                        'TUV': 'Tuvalu',
                        'TWN': 'Taiwan',
                        'UKR': 'Ukraine',
                        'UGA': 'Uganda',
                        'USA': 'United States of America',
                        'URY': 'Uruguay',
                        'UZB': 'Uzbekistan',
                        'VAT': 'Vatican City',
                        'VCT': 'St. Vincent and the Grenadines',
                        'VEN': 'Venezuela',
                        'VGB': 'British Virgin Islands',
                        'VIR': 'Virgin Islands of the United States of America',
                        'VNM': 'Vietnam',
                        'VUT': 'Vanuatu',
                        'WLF': 'Wallis and Futuna',
                        'WSM': 'Samoa',
                        'YEM': 'Yemen',
                        'MYT': 'Mayotte',
                        'ZAF': 'South Africa',
                        'ZMB': 'Zambia',
                        'ZWE': 'Zimbabwe',
                        'COD': 'Democratic Republic of Congo',
                        'OSA': 'Kosovo',
                        '-99': 'Northern Cyprus',
                        'SDS': 'Senegal',
                        'ABV': 'Somaliland',
                        'Citations': 'Citations',
                        'Publications': 'Products',
                        'Citaciones': 'Citations',
                        'Productos': 'Products',
                        'Tesis de pregrado': 'Undergraduate thesis',
                        'Tesis de especialización': 'Specialization thesis',
                        'Tesis de maestría': 'Master\'s Thesis',
                        'Tesis de doctorado': 'Doctoral thesis',
                        'Total femenino': 'Total female',
                        'Total masculino': 'Total male',
                        'Directoras': 'Female directors',
                        'Directores': 'Male directors',
                        'No directoras': 'No female directors',
                        'No directores': 'No male directors',
                        'Femenino': 'Female',
                        'Masculino': 'Male',
                        'Activo': 'Asset',
                        'Retirado': 'Retired',
                        'No Reportado': 'Not reported',
                        'Ciencias naturales': 'Natural Sciences',
                        'Ingeniería y tecnología': 'Engineering and technology',
                        'Ciencias médicas y de la salud': 'Medical and health sciences',
                        'Ciencias sociales': 'Social Sciences',
                        'Ciencias agrícolas': 'Agricultural science',
                        'Humanidades': 'Humanities',
                        'Energía': 'Energy',
                        'Ambiente Y Biodiversidad': 'Environment and Biodiversity',
                        'Salud Y Vida': 'Health and life',
                        'Ciencias Agrarias Y Desarrollo Rural': 'Agrarian Sciences and Rural Development',
                        'CyT De Minerales Y Materiales': 'S&T of Minerals and Materials',
                        'Tecnologías De La Información Y Comunicaciones': 'Information and Communications Technologies',
                        'Desarrollo Organizacional Económico E Industrial': 'Economic and Industrial Organizational Development',
                        'Biotecnología': 'Biotechnology',
                        'Hábitat, Ciudad Y Territorio': 'Habitat, City and Territory',
                        'Estado, Sistemas Políticos Y Jurídicos': 'State, Political and Legal Systems',
                        'Construcción De Ciudadanía E Inclusión Social': 'Construction of Citizenship and Social Inclusion',
                        'Arte Y Cultura': 'Art and culture',
                        'Aprobado': 'Approved',
                        'No Aprobado': 'Not approved',
                        'Finalizado': 'Finalized',
                        'Cancelado': 'Cancelled',
                        'Duración Original': 'Original Duration',
                        'Duración Real': 'Actual Duration',
                        'Otras': 'Others',
                        '  Extensión Solidaria': 'Solidarity Extension',
                        'Acreditación de Laboratorios': 'Laboratory Accreditation',
                        'Conformación y fortalecimiento de grupos y redes de investigación': 'Formation and strengthening of research groups and networks',
                        'Creación artística': 'Artistic creation',
                        'Gestión académica de la Investigación': 'Academic Research Management',
                        'Investigación Básica': 'Basic investigation',
                        'Investigación aplicada ': 'Applied research',
                        'Investigación experimental': 'Experimental research',
                        'Programas o Megaproyectos de Soporte Institucional': 'Institutional Support Programs or Megaprojects',
                        'Programas y megaproyectos que articulen formación, investigación y extensión': 'Programs and megaprojects that articulate training, research and extension',
                        'DotaciónAdquisición equipo Científico y Tecnológico': 'EndowmentAcquisition of Scientific and Technological equipment',
                        'Proyectos de Regalías CyT': 'CyT Royalty Projects',
                        'Líderes femeninas': 'Female leaders',
                        'No líderes': 'Not leaders',
                        'Líderes masculinos': 'Male leaders',
                        'Investigación básica': 'Basic investigation',
                        'Investigación aplicada': 'Applied research',
                        'Estudiante - Maestria': 'Student - Master',
                        'Estudiante - Sin Dato': 'Student - No Data',
                        'Estudiante - Doctorado': 'Student - PhD',
                        'Docente': 'Teacher',
                        'Líder': 'Leader',
                        'Co-Líder': 'Co-Leader'
                    },
                    'range': {
                        'extIntervalTime': {
                            'name': 'Time interval',
                            'tooltip': 'Press to open time range options'
                        },
                        'extRangeTime': {
                            'tooltip': 'Press to open the time filter',
                            'filter': 'Time filter',
                            'startYear': 'Start year',
                            'endYear': 'End year',
                            'applied': 'Date range applied to filter'
                        },
                        'invRangeTime': {
                            'tooltip': 'Press to open the time filter',
                            'filter': 'Time filter',
                            'startYear': 'Start year',
                            'endYear': 'End year',
                            'applied': 'Date range applied to filter'
                        }
                    },
                    'breadcrumbs': {
                        'tooltip': 'Dependence',
                        'general': 'General',
                        'research': 'Research',
                        'extension': 'Extended projects',
                        'filter': 'Change the hierarchy filter by clicking on '
                    },
                    'widgets': {
                        'widget': {
                            'tooltip': 'Change with time filter.'
                        }
                    }
                },
                'dependence': {
                    'investigation': {
                        'year': 'Year',
                        'percentage': 'Percentage',
                        'gender': 'Gender',
                        'type': 'Type',
                        'amount': 'Amount',
                        'dependence': 'Dependence',
                        'country': 'Country',
                        'institutions': 'Institutions',
                        'journals': 'Journals',
                        'classification': 'Classification',
                        'products': 'Publications',
                        'citations': 'Citations',
                        'projects': 'Projects',
                        'themesAndWorkAreas': 'Research Themes and Areas',
                        'productsList': 'List of Publications and Projects',
                        'groups': 'Research Groups',
                        'researchers': 'Research Professors',
                        'groupMembers': 'Group Members',
                        'mincienciasCalls': 'Minciencias Calls',
                        'consultingInfoProfessor': 'Consulting professor information.',
                        'consultingInfoCampus': 'Consulting campus information.',
                        'consultingInfoFaculty': 'Consulting faculty information.',
                        'consultingInfoProjects': 'Consulting projects information.',
                        'consultingInfoProducts': 'Consulting products information.',
                        'consultingInfoGroup': 'Consulting group information.',
                        'consultingInfoGroups': 'Consulting groups information.',
                        'consultingInfoInstitution': 'Consulting institution information.',
                        'consultingInfoUab': 'Consulting uab information.',
                        'consultingInfoWorkAreas': 'Consulting work areas information.',
                        'consultingInfoResearchers': 'Consulting researchers information.',
                        'consultingInfoListProducts': 'Consulting product list information.',
                        'wordCloud': 'Word Cloud',
                        'themeGraph': 'Theme Graph',
                        'lda': 'Automatic topic detection through generative model',
                        'collaborations': 'General Collaborations',
                        'wordCloudInfo': 'Three categories of word clouds are offered: First level topics are those that denote large categories of controlled vocabularies such as Mesh for medicine or IEEE for engineering, each category can encompass multiple more specific terms that belong to the second word cloud, second level topics. Finally, a general word cloud is provided which lists keywords representing the work done by a professor, uab or faculty.',
                        'researchGroups': {
                            'name': 'Name',
                            'bonding': 'Bonding',
                            'status': 'Status',
                            'leader': 'Leader',
                            'researchGroupsList': 'Research Groups List',
                            'amountGroupsByCategory': 'Number of research groups by category according to MinCiencias',
                            'infoAmountGroupsByCategory': 'It shows the number of Minciencias groups, by category.',
                            'amountGroupsByOCDE': 'Number of research groups by OECD Area',
                            'infoAmountGroupsByOCDE': 'It shows the number of research groups per OECD area, when hovering over the labels the corresponding strip will be highlighted and by clicking on the label the portion of the disk can be hidden or shown.',
                            'amountGroupsByAgenda': 'Number of research groups by main knowledge agenda',
                            'infoAmountGroupsByAgenda': 'It shows the number of research groups by main knowledge agenda, by clicking on the labels the corresponding strip will be highlighted and by clicking on the label the portion of the disk can be hidden or shown.',
                            'groupCategory': 'Group category',
                            'groupAmount': 'Number of groups',
                            'OCDEArea': 'OCDE area',
                            'knowledgeAgenda': 'Knowledge agenda',
                            'noResearchGroups': 'No research groups',
                            'category': 'Category',
                            'memberType': 'Member type',
                            'membersType': 'Members type',
                            'amountMembers': 'Number of members',
                            'hermesId': 'Hermes ID',
                            'colcienciasCode': 'Colciencias code',
                            'groupMembersList': 'Group members list',
                            'information': 'Group information'
                        },
                        'publicationsAmount': 'Number of products',
                        'researchersMain': {
                            'image': 'Photography',
                            'name': 'Name',
                            'campus': 'Campus',
                            'faculty': 'Faculty',
                            'uab': 'Uab',
                            'bonding': 'Affiliation',
                            'researcherProfessorsList': 'List of research professors',
                            'activeProfessorsPerYearWhoPublish': 'Active professors publishing by year',
                            'activeProfessorsPerYear': 'Active professors by year',
                            'genderDistribution': 'Professor distribution by gender',
                            'amountByAgeGroup': 'Number of researchers by age group',
                            'professorsAmount': 'Number of professors',
                            'ageGroup': 'Age group',
                            'tooltipFilter': 'Click to filter products'
                        },
                        'projectsMain': {
                            'amountApprovedProjects': 'Number of approved projects',
                            'amountApprovedProjectsInfo': 'It shows the number of approved and unapproved projects.',
                            'amountApprovedProjectsByYear': 'Number of approved projects by year',
                            'amountApprovedProjectsByYearInfo': 'It shows the number of approved and unapproved projects by year.',
                            'amountApprovedProjectStatuses': 'Number of approved projects\' statuses',
                            'amountApprovedProjectStatusesInfo': 'It shows the number of approved project statuses.',
                            'amountApprovedProjectStatusesByYear': 'Number of approved projects\' statuses by year',
                            'amountApprovedProjectStatusesByYearInfo': 'It shows the number of approved project statuses per year.',
                            'durationComparisonByMonth': 'Comparison of projects duration by month',
                            'durationComparisonByMonthInfo': 'It compares the number of projects whose actual planned duration is X months vs. the original planned duration.',
                            'amountProjectsByType': 'Number of projects by type',
                            'amountProjectsByTypeInfo': 'It shows the number of projects approved by type, by clicking on the desired label(s) you can hide or show the fraction of the corresponding disk, likewise, by clicking on the portion of the disk the number that represents that fraction will be shown.',
                            'amountProjectsByTypePerYear': 'Number of projects by type per year',
                            'amountProjectsByTypePerYearInfo': 'Number of projects approved by type and by year, which will allow us to know which was the predominant type of project in a given year.',
                            'leadersProportionByGender': 'Proportion of project leaders by gender',
                            'leadersProportionByGenderInfo': 'Proportion of projects approved by typology and by year, which will allow us to know which was the predominant project typology in a given year.',
                            'projectDirectorsByGender': 'Number of project directors by gender and type',
                            'projectDirectorsByGenderInfo': 'Number of project leaders by gender and by type of Basic Research and Applied Research.',
                            'generalCollaborations': 'General Collaborations',
                            'projectType': 'Project type',
                            'projectTypology': 'Project typology',
                            'projectStatus': 'Project status',
                            'projectAmount': 'Number of projects',
                            'projectDurationMonths': 'Projects duration (months)',
                            'durationType': 'Duration type',
                            'typologyType': 'Typology type',
                            'directorsAmount': 'Number of directors'
                        },
                        'productsMain': {
                            'amountPublicationsByType': 'Number of publications by type',
                            'amountPublicationsByTypeInfo': 'This table shows the list of types of publications with their corresponding frequency from lowest to highest. Among its most relevant features is the disaggregation of statistics by gender and the download of the CSV file with the required information.',
                            'amountPublicationsByTypeAndYear': 'Number of publications by type and year',
                            'amountPublicationsByTypeAndYearInfo': 'Number of publications by type and by year, which will allow to know which was the predominant type of product in a given year, it has a gender filter and an option to download the data.',
                            'articleRate': 'Article rate',
                            'articleRateInfo': 'It represents the average number of articles per professor in a given year. Only active teachers for the year consulted are taken into account.',
                            'amountPublicationsNationalAndInternational': 'Number of publications at national and international level',
                            'amountPublicationsNationalAndInternationalInfo': 'Presents the number of articles published in national and international journals for each year.',
                            'scimagoRankingOverTime': 'Scimago ranking classification over time',
                            'scimagoRankingOverTimeInfo': 'The Scimago ranking rates each journal according to its ISSN with a value from 0 onwards in each year measured. Therefore, the indicator shows the volume of the Scimago rating in the corresponding time according to the scale defined to group the same.',
                            'topPublicationsByJournal': 'Top publications by journal and/or conference',
                            'topPublicationsByJournalInfo': 'It shows the ranking of journals and conferences in which professors publish their work.',
                            'topPublicationsByInstitution': 'Top publications by institution',
                            'topPublicationsByInstitutionInfo': 'Shows the ranking of institutions with which the university has the most collaborations in the production and publication of research products.',
                            'amountCollaborationsByCountry': 'Number of collaborations by country',
                            'amountCollaborationsByCountryInfo': 'This allows you to see the number of collaborations with other countries. When you click on a country, it will be highlighted and the number of publications made jointly with researchers and/or institutions from that country will be shown.',
                            'articleRatePublishedBy': 'Rate of articles published by ',
                            'articleRatePublishedByInfo': 'It performs a comparative analysis of the article rates for each sub-level of the organizational structure. For example, if you query a faculty, you can see the comparison of the article rate of each of its UABs.',
                            'productionBy': 'Production by ',
                            'productionByInfo': 'It allows you to see a comparison of the number of publications and citations of the units corresponding to the next hierarchical level, i.e., if you are viewing a faculty, it will show the comparison of the uab\'s assigned to this faculty, if it is a site, the comparison of the faculties.',
                            'amountThesisDirectors': 'Thesis directors by gender (Quantity and Proportion)',
                            'amountThesisDirectorsInfo': 'Women and men directing undergraduate, graduate, master\'s, and doctoral theses.',
                            'publicationType': 'Publication type',
                            'modalityType': 'Modality type',
                            'amountDirectorsQuantity': 'Number of directors and teachers',
                            'amountDirectorsProportion': 'Proportion of directors and teachers',
                            'publicationsAndCitations': 'Products and Citations',
                            'productionType': 'Production type',
                            'collaborationsAmount': 'Number of collaborations',
                            'amountPublicationsByScimago': 'Number of publications by Scimago classification'
                        },
                        'areasMain': {
                            'wordCloudType': 'Words',
                            'wordCloudValue': 'Amount'
                        }
                    },
                    'extension': {
                        'generalInformation': 'General information',
                        'projects': 'Projects',
                        'opportunities': 'Opportunities',
                        'activities': 'Activities',
                        'consultingInfoInstitution': 'Consulting information about the institution.',
                        'consultingInfoGeneral': 'Consulting general information.',
                        'consultingInfoProjects': 'Consulting project information.',
                        'consultingInfoOpportunities': 'Consulting information on opportunities.',
                        'consultingInfoActivities': 'Consulting activity information.',
                        'generalMain': {
                            'projectsCost': 'Project costs',
                            'projectsCostInfo': '',
                            'projectsCostNotice': 'The data in the graph corresponds to Continuing and Permanent Education',
                            'projectsCostValue': 'Costs',
                            'projectsCostAxisY': 'Costs in billions of COP pesos',
                            'projectEarnings': 'Project earnings',
                            'projectEarningsInfo': '',
                            'projectEarningsNotice': 'The data in the graph corresponds to Academic Services and Continuing and Permanent Education',
                            'projectEarningsType': 'Modality',
                            'projectEarningsValue': 'Profits',
                            'projectEarningsAxisY': 'Profits in billions of COP pesos',
                            'contractedEntities': 'Contracted entities',
                            'contractedEntitiesInfo': '',
                            'contractedEntitiesNotice': 'The data in the graph corresponds to Academic Services',
                            'contractedEntitiesType': 'Entities',
                            'contractedEntitiesValue': 'Number of hires',
                            'valueContractedEntities': 'Value of contracted entities',
                            'valueContractedEntitiesInfo': '',
                            'valueContractedEntitiesNotice': 'The data in the graph corresponds to Academic Services',
                            'valuecontractedEntitiesType': 'Entities',
                            'valuecontractedEntitiesValue': 'Hiring value',
                            'educationActivities': 'Continuing education activities by region',
                            'educationActivitiesInfo': '',
                            'educationActivitiesNotice': 'The data in the graph corresponds to Continuing and Permanent Education',
                            'educationActivitiesType': 'Region',
                            'educationActivitiesValue': 'Activities'
                        },
                        'projectsMain': {
                            'numberProjects': 'Number of projects',
                            'numberProjectsInfo': '',
                            'numberProjectsNotice': 'The data in the graph corresponds to Academic Services',
                            'numberProjectsValue': 'Number of projects',
                            'numberProjectsAxisY': 'Number of projects',
                            'valueProjects': 'Project value',
                            'valueProjectsInfo': '',
                            'valueProjectsNotice': 'The data in the graph corresponds to Academic Services',
                            'valueProjectsValue': 'Value',
                            'valueProjectsAxisY': 'Value of the projects in billions of COP pesos',
                            'classificationProjects': 'Classification of the nature of projects',
                            'classificationProjectsInfo': '',
                            'classificationProjectsNotice': 'The data in the graph corresponds to Academic Services',
                            'classificationProjectsType': 'Classification',
                            'classificationProjectsValue': 'Number of projects',
                            'classificationProjectsAxisY': 'Number of projects',
                            'managerProjects': 'Extension Project Directors',
                            'managerProjectsInfo': '',
                            'managerProjectsNotice': 'The data in the graph corresponds to Academic Services',
                            'managerProjectsType': 'Directors',
                            'managerProjectsValue': 'Amount',
                            'origenProjects': 'Origin of project proposals',
                            'origenProjectsInfo': '',
                            'origenProjectsNotice': 'The data in the graph corresponds to Continuing and Permanent Education',
                            'origenProjectsType': 'Type',
                            'origenProjectsValue': 'Amount',
                            'wordCloudProjects': 'Word Cloud',
                            'wordCloudProjectsInfo': '3 categories of word clouds are offered: The first level topics are those that denote large categories of controlled vocabularies such as Mesh for medicine or IEEE for engineering, each category can encompass multiple more specific terms that belong to the second word cloud, topics second level. Finally, a general cloud is offered which contains keywords that represent the work carried out by a professor, UAB or faculty.',
                            'wordCloudProjectsNotice': ''
                        },
                        'opportunitiesMain': {
                            'statusComparison': 'Opportunity Status Comparison',
                            'statusComparisonInfo': '',
                            'statusComparisonNotice': '',
                            'statusComparisonType': 'Status Type',
                            'statusComparisonValue': 'Number of opportunities',
                            'statusComparisonAxisY': 'Number of opportunities'
                        },
                        'activitiesMain': {
                            'numberActivities': 'Number of activities per time period',
                            'numberActivitiesInfo': '',
                            'numberActivitiesNotice': 'The data in the graph corresponds to Continuing and Permanent Education',
                            'numberActivitiesGraphValue': 'Number of activities',
                            'numberActivitiesGraphAxisY': 'Number of activities',
                            'numberParticipants': 'Number of participants per time period',
                            'numberParticipantsInfo': '',
                            'numberParticipantsNotice': 'The data in the graph corresponds to Continuing and Permanent Education',
                            'numberParticipantsGraphValue': 'Amount of participants',
                            'numberParticipantsGraphAxisY': 'Amount of participants',
                            'numberTopology': 'Number of activities carried out according to the ECP typology',
                            'numberTopologyInfo': '',
                            'numberTopologyNotice': 'The data in the graph corresponds to Continuing and Permanent Education',
                            'numberTopologyType': 'ECP Typology',
                            'numberTopologyValue': 'Number of activities',
                            'numberTopologyGraphAxisY': 'Number of activities',
                            'hourlyIntensity': 'Description of the hourly intensity of each submodality',
                            'hourlyIntensityInfo': '',
                            'hourlyIntensityNotice': 'The data in the graph corresponds to Continuing and Permanent Education',
                            'hourlyIntensityGraphValue': 'Hourly intensity',
                            'hourlyIntensityGraphAxisY': 'Hourly intensity',
                            'participantsSubModality': 'Number of participants per submodality',
                            'participantsSubModalityInfo': '',
                            'participantsSubModalityNotice': 'The data in the graph corresponds to Continuing and Permanent Education',
                            'participantsSubModalityType': 'Submodality type',
                            'participantsSubModalityValue': 'Amount of participants',
                            'participantsSubModalityAxisY': 'Amount of participants',
                            'numberSubModality': 'Number of activities per submodality',
                            'numberSubModalityInfo': '',
                            'numberSubModalityNotice': 'The data in the graph corresponds to Continuing and Permanent Education',
                            'numberSubModalityType': 'Submodality type',
                            'numberSubModalityValue': 'Number of activities',
                            'numberSubModalityAxisY': 'Number of activities'
                        }
                    }
                },
                'reports': {
                    'consultingInfoSearch': 'Consulting information.',
                    'buttonPanel': 'Left side panel',
                    'infoPanelTrue': 'Please press to open the left side panel and select one of the reports.',
                    'infoPanelFalse': 'Please select one of the reports in the left side panel.',
                    'requestUrl': 'Request url',
                    'copyClipboardUrl': 'Copy the request link to the clipboard',
                    'topic': 'Topic',
                    'institution': 'Institution',
                    'campus': 'Campus',
                    'faculty': 'Faculty',
                    'uab': 'Uab',
                    'identifier': 'Identifier',
                    'email': 'Email',
                    'gender': 'Gender',
                    'bonding': 'Bonding',
                    'copyClipboard': 'Copy report information to clipboard',
                    'downloadReportJSON': 'Download the report in JSON format',
                    'downloadReportEXCEL': 'Download the report in EXCEL format'
                }
            }
        },
        'es': {
            'translation': {
                'header': {
                    'investigation': 'Investigación',
                    'extension': 'Proyectos extendidos',
                    'extensionFacultadIngenieria': 'Extensión Facultad de Ingeniería',
                    'wiki': 'Acerca de',
                    'language': 'Idioma',
                    'services': 'Servicios',
                    'email': 'Correo Electrónico',
                    'sia': 'DINARA - SIA',
                    'libraries': 'Bibliotecas',
                    'calls': 'Convocatorias',
                    'identity': 'Identidad UNAL',
                    'login': 'Inicio de sesión',
                    'reports': 'Reportes',
                    'accessibility': 'Panel de Accesibilidad',
                    'letterSize': 'Tamaño letra',
                    'changeContrasts': 'Cambiar Contrastes',
                    'invertColors': 'Invertir colores',
                    'resetSettings': 'Restablecer ajustes',
                    'apply': 'Aplicar'
                },
                'footer': {
                    'legalRegime': 'Régimen legal',
                    'humanTalent': 'Talento humano',
                    'hiring': 'Contratación',
                    'jobVacancies': 'Ofertas de empleo',
                    'accountability': 'Rendición de cuentas',
                    'teachingCompetition': 'Concurso docente',
                    'virtualPayment': 'Pago Virtual',
                    'internalControl': 'Control interno',
                    'quality': 'Calidad',
                    'notificationMailbox': 'Buzón de notificaciones',
                    'institutionalEmail': 'Correo institucional',
                    'siteMap': 'Mapa del sitio',
                    'socialNetworks': 'Redes Sociales',
                    'faq': 'FAQ',
                    'complaintsClaims': 'Quejas y reclamos',
                    'onlineAttention': 'Atención en línea',
                    'survey': 'Encuesta',
                    'contactUs': 'Contáctenos',
                    'statistics': 'Estadísticas',
                    'glossary': 'Glosario',
                    'contactWebsite': 'Contacto página web',
                    'contactInfo': 'Comuníquese con el administrador de este sitio web',
                    'author': 'Algunos derechos reservados.<br />Grupo de Investigación MIDAS<br />Dir: Ing. Elizabeth León Guzmán, Ph.D.<br />',
                    'wiki': 'Acerca de este sitio web',
                    'update': 'Actualización'
                },
                'home': {
                    'description': 'Visualización de la <b>capacidad investigativa</b> y <b>producción científica</b> de la <b>Universidad Nacional de Colombia.</b>',
                    'publicationsBoards': 'Tableros de <b>publicaciones</b>',
                    'researchersProfile': 'Perfil de <b>investigadores</b>'
                },
                'components': {
                    'search': {
                        'SearchTopics': {
                            'name': 'Temas',
                            'placeholder': 'Búsqueda por tema',
                            'tooltip': 'Presione para realizar la búsqueda',
                            'gateInfo': 'Presione para cambiar la compuerta lógica de búsqueda de',
                            'gateTo': 'a',
                            'consultingInfoSearch': 'Consultando información.'
                        },
                        'SearchDependences': {
                            'name': 'Dependencias y Autores',
                            'placeholder': 'Búsqueda por dependencias y autores',
                            'results': 'Resultados de la búsqueda',
                            'tooltip': 'Presione para limpiar el campo de búsqueda'
                        }
                    },
                    'card': {
                        'cardAuthor': {
                            'email': 'Correo Electrónico',
                            'bonding': 'Vinculación',
                            'tooltipInfo': ' del profesor',
                            'profiles': 'Perfiles Académicos',
                            'tooltipPlat': 'Ir al perfil del profesor en ',
                            'hIndex': 'Índice H',
                            'DOCENTE TIEMPO COMPLETO': 'Docente Tiempo Completo',
                            'DOCENTE DEDICAC. EXCLUSIVA': 'Docente Dedicac. Exclusiva',
                            'DOCENTE CATEDRA 0,4': 'Docente Catedra 0,4',
                            'DOCENTE CATEDRA 0,5': 'Docente Catedra 0,5',
                            'DOCENTE CATEDRA 0,2': 'Docente Catedra 0,2',
                            'DOCENTE CATEDRA 0,3': 'Docente Catedra 0,3',
                            'DOCENTE MEDIO TIEMPO': 'Docente Medio Tiempo',
                            'DOCENTE CATEDRA 0,6': 'Docente Catedra 0,6',
                            'DOCENTE CATEDRA 0,7': 'Docente Catedra 0,7',
                            'DOCENTE CATEDRA 0,1': 'Docente Catedra 0,1',
                            'DOCENTE CATEDRA 0,0': 'Docente Catedra 0,0',
                            'BECARIO PREGRADO 3 HORAS': 'Becario Pregrado 3 Horas',
                            'ADMINISTRATIVO T. COMPLETO': 'Administrativo T. Completo',
                            'PROFESOR ASOCIADO': 'Profesor Asociado',
                            'PROFESOR TITULAR': 'Profesor Titular',
                            'PROFESOR AUXILIAR': 'Profesor Auxiliar',
                            'PROFESOR ASISTENTE': 'Profesor Asistente',
                            'INSTRUCTOR ASOCIADO': 'Instructor Asociado',
                            'EXPERTO III': 'Experto Iii',
                            'INSTRUCTOR ASISTENTE': 'Instructor Asistente',
                            '.': 'Not reported',
                            'NO VIGENTE - VICERRECTOR': 'No Vigente - Vicerrector'
                        },
                        'cardEmpty': {
                            'empty': 'No se han encontrado resultados'
                        },
                        'cardGraphics': {
                            'filterTime': 'Los datos de la gráfica cambian con el filtro de tiempo',
                            'genderTooltip': 'Seleccione una opción para cambiar los datos de la gráfica en base al genero',
                            'gender': 'Género',
                            'typeTooltip': 'Seleccione una opción para cambiar las métricas respecto al tipo de producto',
                            'type': 'Tipo de producto',
                            'accumulateTrueTooltip': 'Presione para mostrar los datos acumulados',
                            'accumulateTrue': 'Acumulado',
                            'accumulateFalseTooltip': 'Presione para no mostrar los datos acumulados',
                            'accumulateFalse': 'No acumulado',
                            'wordCloudTypeTooltip': 'Seleccione una opción para cambiar el tipo de nube de palabras',
                            'wordCloudType': 'Temas',
                            'wordCloudLanguageTooltip': 'Seleccione una opción para cambiar el idioma',
                            'wordCloudLanguage': 'Cambiar idioma',
                            'typeChart': 'Tipo de gráfica',
                            'typeChartTooltip': 'Seleccione una opción para cambiar el tipo de gráfica',
                            'downloadImage': 'Descargar imagen',
                            'downloadCsv': 'Descargar como CSV',
                            'print': 'Imprimir',
                            'dialog': 'Acerca de la gráfica',
                            'dataTableTrueTooltip': 'Ver tabla de datos',
                            'dataTableFalseTooltip': 'Ocultar tabla de datos',
                            'export': 'Exportar',
                            'dialogClose': 'Cerrar'
                        },
                        'cardGroup': {
                            'email': 'Correo electrónico',
                            'date': 'Fecha de registro',
                            'state': 'Estado',
                            'informationTooltip': ' del grupo',
                            'colciencias': 'Información Colciencias',
                            'colcienciasCode': 'Código',
                            'colcienciasCategory': 'Categoría',
                            'colcienciasState': 'Estado',
                            'profiles': 'Perfiles Académicos',
                            'linkHermes': 'Enlace a Hermes',
                            'linkGrupLac': 'Enlace a GrupLAC',
                            'lines': 'Líneas de Investigación',
                            'leader': 'Información del líder'
                        },
                        'cardNews': {
                            'title': 'Noticias HORUS'
                        },
                        'cardProductsList': {
                            'searchContent': 'Buscar por contenido',
                            'authorTrue': 'Dependencias y autores',
                            'authorFalse': 'Directores',
                            'authorFalseVariant': 'Submodalidad',
                            'typeTrue': 'Tipo de producto',
                            'typeFalse': 'Tipo de modalidad',
                            'dateTrue': 'Fecha',
                            'dateFalse': 'Fecha inicio - Fecha fin',
                            'order': 'Ordenar por',
                            'orderDate': 'Ordenar por fecha',
                            'orderCitations': 'Ordenar por citaciones',
                            'orderValue': 'Ordenar por valor',
                            'orderRelevance': 'Ordenar por relevancia',
                            'orderCitationsTrue': 'Citaciones',
                            'orderCitationsFalse': 'Valor',
                            'orderCitationsFalseVariant': 'Número de participantes',
                            'orderAsc': 'Orden ascendente',
                            'orderDsc': 'Orden descendente',
                            'download': 'Descargar productos',
                            'labelRows': 'Filas por pagina',
                            'labelRowsOf': 'de',
                            'title': 'Título',
                            'abstractTrue': 'Resumen',
                            'abstractFalse': 'Entidad contratante',
                            'abstractFalseVariant': 'Objecto del contrato',
                            'relevance': 'Relevancia',
                            'languageTrue': 'Idioma original del producto',
                            'languageFalse': 'Idioma original del producto',
                            'languageFalseVariant': 'Intensidad horaria',
                            'correctionsApplied': 'Correcciones aplicadas',
                            'correctionsPending': 'Correcciones pendientes',
                            'linkProduct': 'Enlace al producto',
                            'curatorTrue': 'Ver el producto',
                            'curatorFalse': 'Corregir el producto',
                            'linkAuthor': 'Visitar el perfil del profesor'
                        }
                    },
                    'graphics': {
                        'choroplethWorMap': {
                            'collaborations': 'Colaboraciones'
                        },
                        'graph3D': {
                            'labelRadial': 'Radial',
                            'labelRandom': 'Aleatorio',
                            'labelDirect': 'Directa',
                            'labelIndirect': 'Indirecta',
                            'labelGeneral': 'General',
                            'labelUniversity': 'Universidad',
                            'typeTooltip': 'Seleccione el tipo de grafo que desea visualizar',
                            'type': 'Tipo de grafo',
                            'searchTooltip': 'Ingrese o seleccione la opción que desea buscar en el grafo',
                            'search': 'Buscar en el grafo',
                            'defaultGraph': 'Presione para ver el grafo por defecto',
                            'screenAll': 'Presione para visualizar el grafo en pantalla completa',
                            'connectionTooltip': 'Seleccione una opción para cambiar el tipo de red',
                            'connection': 'Tipo de red',
                            'collaborationTooltip': 'Seleccione una opción para cambiar el tipo de colaboración',
                            'collaboration': 'Tipo de colaboración',
                        },
                        'Artículo de revista': 'Artículo de revista',
                        'Estado del arte': 'Estado del arte',
                        'Tesis Pregrado': 'Tesis Pregrado',
                        'Capítulo de libro': 'Capítulo de libro',
                        'Libro': 'Libro',
                        'Tesis Maestría': 'Tesis Maestría',
                        'Artículo de conferencia': 'Artículo de conferencia',
                        'Reporte tecnico': 'Reporte tecnico',
                        'Formato audiovisual': 'Formato audiovisual',
                        'Colección científica': 'Colección científica',
                        'Tesis Especialización': 'Tesis Especialización',
                        'Tesis Doctorado': 'Tesis Doctorado',
                        'Patente': 'Patente',
                        'Editorial': 'Editorial',
                        'Desarrollo de software': 'Desarrollo de software',
                        'Proceso instrumental': 'Proceso instrumental',
                        'Datos': 'Datos',
                        'Ponencias en eventos': 'Ponencias en eventos',
                        'Posters presentados': 'Posters presentados',
                        'Biografía': 'Biografía',
                        'Preimpresión': 'Preimpresión',
                        'Proyecto': 'Proyecto',
                        'Comentario': 'Comentario',
                        'Errata': 'Errata',
                        'Estudio observacional': 'Estudio observacional',
                        'Estudio multicéntrico': 'Estudio multicéntrico',
                        'Apoyo a la investigación': 'Apoyo a la investigación',
                        'Reporte de caso': 'Reporte de caso',
                        'Estudio comparativo': 'Estudio comparativo',
                        'Revisión sistemática': 'Revisión sistemática',
                        'Internacional': 'Internacional',
                        'Nacional': 'Nacional',
                        'AND': 'Andorra',
                        'ARE': 'Emiratos Árabes Unidos',
                        'AFG': 'Afganistán',
                        'ATG': 'Antigua y Barbuda',
                        'AIA': 'Anguila',
                        'ALB': 'Albania',
                        'ARM': 'Armenia',
                        'ANT': 'Antillas Neerlandesas',
                        'AGO': 'Angola',
                        'ATA': 'Antártida',
                        'ARG': 'Argentina',
                        'ASM': 'Samoa Americana',
                        'AUT': 'Austria',
                        'AUS': 'Australia',
                        'ABW': 'Aruba',
                        'ALA': 'Islas Áland',
                        'AZE': 'Azerbaiyán',
                        'BIH': 'Bosnia y Herzegovina',
                        'BRB': 'Barbados',
                        'BGD': 'Bangladesh',
                        'BEL': 'Bélgica',
                        'BFA': 'Burkina Faso',
                        'BGR': 'Bulgaria',
                        'BHR': 'Bahréin',
                        'BDI': 'Burundi',
                        'BEN': 'Benin',
                        'BLM': 'San Bartolomé',
                        'BMU': 'Bermudas',
                        'BRN': 'Brunéi',
                        'BOL': 'Bolivia',
                        'BRA': 'Brasil',
                        'BHS': 'Bahamas',
                        'BTN': 'Bhután',
                        'BVT': 'Isla Bouvet',
                        'BWA': 'Botsuana',
                        'BLR': 'Belarús',
                        'BLZ': 'Belice',
                        'CAN': 'Canadá',
                        'CCK': 'Islas Cocos',
                        'CAF': 'República Centro-Africana',
                        'COG': 'Congo',
                        'CHE': 'Suiza',
                        'CIV': 'Costa de Marfil',
                        'COK': 'Islas Cook',
                        'CHL': 'Chile',
                        'CMR': 'Camerún',
                        'CHN': 'China',
                        'COL': 'Colombia',
                        'CRI': 'Costa Rica',
                        'CUB': 'Cuba',
                        'CPV': 'Cabo Verde',
                        'CXR': 'Islas Christmas',
                        'CYP': 'Chipre',
                        'CZE': 'República Checa',
                        'DEU': 'Alemania',
                        'DJI': 'Yibuti',
                        'DNK': 'Dinamarca',
                        'DMA': 'Domínica',
                        'DOM': 'República Dominicana',
                        'DZA': 'Argel',
                        'ECU': 'Ecuador',
                        'EST': 'Estonia',
                        'EGY': 'Egipto',
                        'ESH': 'Sahara Occidental',
                        'ERI': 'Eritrea',
                        'ESP': 'España',
                        'ETH': 'Etiopía',
                        'FIN': 'Finlandia',
                        'FJI': 'Fiji',
                        'FLK': 'Islas Malvinas',
                        'FSM': 'Micronesia',
                        'FRO': 'Islas Faroe',
                        'FRA': 'Francia',
                        'GAB': 'Gabón',
                        'GBR': 'Reino Unido',
                        'GRD': 'Granada',
                        'GEO': 'Georgia',
                        'GUF': 'Guayana Francesa',
                        'GGY': 'Guernsey',
                        'GHA': 'Ghana',
                        'GIB': 'Gibraltar',
                        'GRL': 'Groenlandia',
                        'GMB': 'Gambia',
                        'GIN': 'Guinea',
                        'GLP': 'Guadalupe',
                        'GNQ': 'Guinea Ecuatorial',
                        'GRC': 'Grecia',
                        'SGS': 'Georgia del Sur e Islas Sandwich del Sur',
                        'GTM': 'Guatemala',
                        'GUM': 'Guam',
                        'GNB': 'Guinea-Bissau',
                        'GUY': 'Guayana',
                        'HKG': 'Hong Kong',
                        'HMD': 'Islas Heard y McDonald',
                        'HND': 'Honduras',
                        'HRV': 'Croacia',
                        'HTI': 'Haití',
                        'HUN': 'Hungría',
                        'IDN': 'Indonesia',
                        'IRL': 'Irlanda',
                        'ISR': 'Israel',
                        'IMN': 'Isla de Man',
                        'IND': 'India',
                        'IOT': 'Territorio Británico del Océano Índico',
                        'IRQ': 'Irak',
                        'IRN': 'Irán',
                        'ISL': 'Islandia',
                        'ITA': 'Italia',
                        'JEY': 'Jersey',
                        'JAM': 'Jamaica',
                        'JOR': 'Jordania',
                        'JPN': 'Japón',
                        'KEN': 'Kenia',
                        'KGZ': 'Kirguistán',
                        'KHM': 'Camboya',
                        'KIR': 'Kiribati',
                        'COM': 'Comoros',
                        'KNA': 'San Cristóbal y Nieves',
                        'PRK': 'Corea del Norte',
                        'KOR': 'Corea del Sur',
                        'KWT': 'Kuwait',
                        'CYM': 'Islas Caimán',
                        'KAZ': 'Kazajstán',
                        'LAO': 'Laos',
                        'LBN': 'Líbano',
                        'LCA': 'Santa Lucía',
                        'LIE': 'Liechtenstein',
                        'LKA': 'Sri Lanka',
                        'LBR': 'Liberia',
                        'LSO': 'Lesotho',
                        'LTU': 'Lituania',
                        'LUX': 'Luxemburgo',
                        'LVA': 'Letonia',
                        'LBY': 'Libia',
                        'MAR': 'Marruecos',
                        'MCO': 'Mónaco',
                        'MDA': 'Moldova',
                        'MNE': 'Montenegro',
                        'MDG': 'Madagascar',
                        'MHL': 'Islas Marshall',
                        'MKD': 'Macedonia',
                        'MLI': 'Mali',
                        'MMR': 'Myanmar',
                        'MNG': 'Mongolia',
                        'MAC': 'Macao',
                        'MTQ': 'Martinica',
                        'MRT': 'Mauritania',
                        'MSR': 'Montserrat',
                        'MLT': 'Malta',
                        'MUS': 'Mauricio',
                        'MDV': 'Maldivas',
                        'MWI': 'Malawi',
                        'MEX': 'México',
                        'MYS': 'Malasia',
                        'MOZ': 'Mozambique',
                        'NAM': 'Namibia',
                        'NCL': 'Nueva Caledonia',
                        'NER': 'Níger',
                        'NFK': 'Islas Norkfolk',
                        'NGA': 'Nigeria',
                        'NIC': 'Nicaragua',
                        'NLD': 'Países Bajos',
                        'NOR': 'Noruega',
                        'NPL': 'Nepal',
                        'NRU': 'Nauru',
                        'NIU': 'Niue',
                        'NZL': 'Nueva Zelanda',
                        'OMN': 'Omán',
                        'PAN': 'Panamá',
                        'PER': 'Perú',
                        'PYF': 'Polinesia Francesa',
                        'PNG': 'Papúa Nueva Guinea',
                        'PHL': 'Filipinas',
                        'PAK': 'Pakistán',
                        'POL': 'Polonia',
                        'SPM': 'San Pedro y Miquelón',
                        'PCN': 'Islas Pitcairn',
                        'PRI': 'Puerto Rico',
                        'PSE': 'Palestina',
                        'PRT': 'Portugal',
                        'PLW': 'Islas Palaos',
                        'PRY': 'Paraguay',
                        'QAT': 'Qatar',
                        'REU': 'Reunión',
                        'ROU': 'Rumanía',
                        'SRB': 'Serbia y Montenegro',
                        'RUS': 'Rusia',
                        'RWA': 'Ruanda',
                        'SAU': 'Arabia Saudita',
                        'SLB': 'Islas Solomón',
                        'SYC': 'Seychelles',
                        'SDN': 'Sudán',
                        'SWE': 'Suecia',
                        'SGP': 'Singapur',
                        'SHN': 'Santa Elena',
                        'SVN': 'Eslovenia',
                        'SJM': 'Islas Svalbard y Jan Mayen',
                        'SVK': 'Eslovaquia',
                        'SLE': 'Sierra Leona',
                        'SMR': 'San Marino',
                        'SEN': 'Senegal',
                        'SOM': 'Somalia',
                        'SUR': 'Surinam',
                        'STP': 'Santo Tomé y Príncipe',
                        'SLV': 'El Salvador',
                        'SYR': 'Siria',
                        'SWZ': 'Suazilandia',
                        'TCA': 'Islas Turcas y Caicos',
                        'TCD': 'Chad',
                        'ATF': 'Territorios Australes Franceses',
                        'TGO': 'Togo',
                        'THA': 'Tailandia',
                        'TZA': 'Tanzania',
                        'TJK': 'Tayikistán',
                        'TKL': 'Tokelau',
                        'TLS': 'Timor-Leste',
                        'TKM': 'Turkmenistán',
                        'TUN': 'Túnez',
                        'TON': 'Tonga',
                        'TUR': 'Turquía',
                        'TTO': 'Trinidad y Tobago',
                        'TUV': 'Tuvalu',
                        'TWN': 'Taiwán',
                        'UKR': 'Ucrania',
                        'UGA': 'Uganda',
                        'USA': 'Estados Unidos de América',
                        'URY': 'Uruguay',
                        'UZB': 'Uzbekistán',
                        'VAT': 'Ciudad del Vaticano',
                        'VCT': 'San Vicente y las Granadinas',
                        'VEN': 'Venezuela',
                        'VGB': 'Islas Vírgenes Británicas',
                        'VIR': 'Islas Vírgenes de los Estados Unidos de América',
                        'VNM': 'Vietnam',
                        'VUT': 'Vanuatu',
                        'WLF': 'Wallis y Futuna',
                        'WSM': 'Samoa',
                        'YEM': 'Yemen',
                        'MYT': 'Mayotte',
                        'ZAF': 'Sudáfrica',
                        'ZMB': 'Zambia',
                        'ZWE': 'Zimbabue',
                        'COD': 'República Democrática del Congo',
                        'OSA': 'Kosovo',
                        '-99': 'Norte de Chipre',
                        'SDS': 'Senegal',
                        'ABV': 'Somalilandia',
                        'Citations': 'Citaciones',
                        'Publications': 'Productos',
                        'Citaciones': 'Citaciones',
                        'Productos': 'Productos',
                        'Tesis de pregrado': 'Tesis de pregrado',
                        'Tesis de especialización': 'Tesis de especialización',
                        'Tesis de maestría': 'Tesis de maestría',
                        'Tesis de doctorado': 'Tesis de doctorado',
                        'Total femenino': 'Total femenino',
                        'Total masculino': 'Total masculino',
                        'Directoras': 'Directoras',
                        'Directores': 'Directores',
                        'No directoras': 'No directoras',
                        'No directores': 'No directores',
                        'Femenino': 'Femenino',
                        'Masculino': 'Masculino',
                        'Activo': 'Activo',
                        'Retirado': 'Retirado',
                        'No Reportado': 'No Reportado',
                        'Ciencias naturales': 'Ciencias naturales',
                        'Ingeniería y tecnología': 'Ingeniería y tecnología',
                        'Ciencias médicas y de la salud': 'Ciencias médicas y de la salud',
                        'Ciencias sociales': 'Ciencias sociales',
                        'Ciencias agrícolas': 'Ciencias agrícolas',
                        'Humanidades': 'Humanidades',
                        'Energía': 'Energía',
                        'Ambiente Y Biodiversidad': 'Ambiente Y Biodiversidad',
                        'Salud Y Vida': 'Salud Y Vida',
                        'Ciencias Agrarias Y Desarrollo Rural': 'Ciencias Agrarias Y Desarrollo Rural',
                        'CyT De Minerales Y Materiales': 'CyT De Minerales Y Materiales',
                        'Tecnologías De La Información Y Comunicaciones': 'Tecnologías De La Información Y Comunicaciones',
                        'Desarrollo Organizacional Económico E Industrial': 'Desarrollo Organizacional Económico E Industrial',
                        'Biotecnología': 'Biotecnología',
                        'Hábitat, Ciudad Y Territorio': 'Hábitat, Ciudad Y Territorio',
                        'Estado, Sistemas Políticos Y Jurídicos': 'Estado, Sistemas Políticos Y Jurídicos',
                        'Construcción De Ciudadanía E Inclusión Social': 'Construcción De Ciudadanía E Inclusión Social',
                        'Arte Y Cultura': 'Arte Y Cultura',
                        'Aprobado': 'Aprobado',
                        'No Aprobado': 'No Aprobado',
                        'Finalizado': 'Finalizado',
                        'Cancelado': 'Cancelado',
                        'Duración Original': 'Duración Original',
                        'Duración Real': 'Duración Real',
                        'Otras': 'Otras',
                        '  Extensión Solidaria': 'Extensión Solidaria',
                        'Acreditación de Laboratorios': 'Acreditación de Laboratorios',
                        'Conformación y fortalecimiento de grupos y redes de investigación': 'Conformación y fortalecimiento de grupos y redes de investigación',
                        'Creación artística': 'Creación artística',
                        'Gestión académica de la Investigación': 'Gestión académica de la Investigación',
                        'Investigación Básica': 'Investigación Básica',
                        'Investigación aplicada ': 'Investigación aplicada',
                        'Investigación experimental': 'Investigación experimental',
                        'Programas o Megaproyectos de Soporte Institucional': 'Programas o Megaproyectos de Soporte Institucional',
                        'Programas y megaproyectos que articulen formación, investigación y extensión': 'Programas y megaproyectos que articulen formación, investigación y extensión',
                        'DotaciónAdquisición equipo Científico y Tecnológico': 'DotaciónAdquisición equipo Científico y Tecnológico',
                        'Proyectos de Regalías CyT': 'Proyectos de Regalías CyT',
                        'Líderes femeninas': 'Líderes femeninas',
                        'No líderes': 'No líderes',
                        'Líderes masculinos': 'Líderes masculinos',
                        'Investigación básica': 'Investigación básica',
                        'Investigación aplicada': 'Investigación aplicada',
                        'Estudiante - Maestria': 'Estudiante - Maestria',
                        'Estudiante - Sin Dato': 'Estudiante - Sin Dato',
                        'Estudiante - Doctorado': 'Estudiante - Doctorado',
                        'Docente': 'Docente',
                        'Líder': 'Líder',
                        'Co-Líder': 'Co-Líder'
                    },
                    'range': {
                        'extIntervalTime': {
                            'name': 'Intervalo de tiempo',
                            'tooltip': 'Presione para abrir las opciones de intervalo de tiempo'
                        },
                        'extRangeTime': {
                            'tooltip': 'Presione para abrir el filtro de tiempo',
                            'filter': 'Filtro de tiempo',
                            'startYear': 'Año inicio',
                            'endYear': 'Año fin',
                            'applied': 'Rango de fechas aplicado al filtro'
                        },
                        'invRangeTime': {
                            'tooltip': 'Presione para abrir el filtro de tiempo',
                            'filter': 'Filtro de tiempo',
                            'startYear': 'Año inicio',
                            'endYear': 'Año fin',
                            'applied': 'Rango de fechas aplicado al filtro'
                        }
                    },
                    'breadcrumbs': {
                        'tooltip': 'Dependencia',
                        'general': 'General',
                        'research': 'Investigación',
                        'extension': 'Proyectos extendidos',
                        'filter': 'Cambia el filtro de jerarquía haciendo clic en '
                    },
                    'widgets': {
                        'widget': {
                            'tooltip': 'Cambia con el filtro de tiempo.'
                        }
                    }
                },
                'dependence': {
                    'investigation': {
                        'year': 'Año',
                        'percentage': 'Porcentaje',
                        'gender': 'Género',
                        'type': 'Tipo',
                        'amount': 'Cantidad',
                        'dependence': 'Dependencia',
                        'country': 'País',
                        'institutions': 'Instituciones',
                        'journals': 'Revistas',
                        'classification': 'Clasificación',
                        'products': 'Publicaciones',
                        'citations': 'Citaciones',
                        'projects': 'Proyectos',
                        'themesAndWorkAreas': 'Temáticas y Areas de trabajo',
                        'productsList': 'Lista de Publicaciones y Proyectos',
                        'groups': 'Grupos de Investigación',
                        'researchers': 'Docentes Investigadores',
                        'groupMembers': 'Miembros del grupo',
                        'mincienciasCalls': 'Convocatorias Minciencias',
                        'consultingInfoProfessor': 'Consultando información del profesor.',
                        'consultingInfoCampus': 'Consultando información de la sede.',
                        'consultingInfoFaculty': 'Consultando información de la facultad.',
                        'consultingInfoProjects': 'Consultando información de proyectos.',
                        'consultingInfoProducts': 'Consultando información de productos.',
                        'consultingInfoGroup': 'Consultando información del grupo.',
                        'consultingInfoGroups': 'Consultando información de grupos.',
                        'consultingInfoInstitution': 'Consultando información de la institución.',
                        'consultingInfoUab': 'Consultando información del uab.',
                        'consultingInfoWorkAreas': 'Consultando información de áreas de trabajo.',
                        'consultingInfoResearchers': 'Consultando información de investigadores.',
                        'consultingInfoListProducts': 'Consultando información de la lista de productos.',
                        'wordCloud': 'Nube de palabras',
                        'themeGraph': 'Grafo de temas',
                        'lda': 'Detección automática de temas a traves de modelo generativo',
                        'collaborations': 'Colaboraciones Generales',
                        'workCloudInfo': 'Se ofrecen 3 categorías de nubes de palabras: Los temas de primer nivel son aquellos que denotan categorias grandes de vocabularios controlados como Mesh para medicina o IEEE para ingenieria, cada categoria puede abarcar multiples terminos mas especificos que pertenecen a la segunda nube de palabras, temas de segundo nivel. Por ultimo se ofrece una nube general la cual consigna palabras clave que representa el trabajo realizado por un profesor, uab o facultad.',
                        'researchGroups': {
                            'name': 'Nombre',
                            'bonding': 'Vinculación',
                            'status': 'Estado',
                            'leader': 'Líder',
                            'researchGroupsList': 'Lista de grupos de investigación',
                            'amountGroupsByCategory': 'Cantidad de grupos de investigación por categoría según MinCiencias',
                            'infoAmountGroupsByCategory': 'Muestra la cantidad de grupos por categoría de Minciencias',
                            'amountGroupsByOCDE': 'Cantidad de grupos de investigación por Área OCDE',
                            'infoAmountGroupsByOCDE': 'Muestra la cantidad de grupos de investigación por area OCDE, al posarse sobre las etiquetas se resaltará la franja correspondientes y si se hace clic sobre la etiqueta la porción del disco puede ocultarse o mostrarse.',
                            'amountGroupsByAgenda': 'Cantidad de grupos de investigación por agenda de conocimiento principal',
                            'infoAmountGroupsByAgenda': 'Muestra la cantidad de grupos de investigación por agenda de conocimiento principal, al posarse sobre las etiquetas se resaltará la franja correspondientes y si se hace clic sobre la etiqueta la porción del disco puede ocultarse o mostrarse.',
                            'groupCategory': 'Categoría del grupo',
                            'groupAmount': 'Cantidad de grupos',
                            'OCDEArea': 'Área OCDE',
                            'knowledgeAgenda': 'Agenda de conocimiento',
                            'noResearchGroups': 'Sin grupos de investigación',
                            'category': 'Categoría',
                            'memberType': 'Tipo de miembro',
                            'membersType': 'Tipo de miembros',
                            'amountMembers': 'Cantidad de miembros',
                            'hermesId': 'Identificador en Hermes',
                            'colcienciasCode': 'Código Colciencias',
                            'groupMembersList': 'Lista de miembros del grupo',
                            'information': 'Información del grupo'
                        },
                        'publicationsAmount': 'Cantidad de productos',
                        'researchersMain': {
                            'image': 'Fotografía',
                            'name': 'Nombre',
                            'campus': 'Sede',
                            'faculty': 'Facultad',
                            'uab': 'Uab',
                            'bonding': 'Vinculación',
                            'researcherProfessorsList': 'Lista de docentes investigadores',
                            'activeProfessorsPerYearWhoPublish': 'Profesores activos que publican por año',
                            'activeProfessorsPerYear': 'Profesores activos por año',
                            'genderDistribution': 'Distribución de docentes por género',
                            'amountByAgeGroup': 'Cantidad de investigadores por grupo etario',
                            'professorsAmount': 'Cantidad de docentes',
                            'ageGroup': 'Grupo etario',
                            'tooltipFilter': 'Presione para filtrar los productos'
                        },
                        'projectsMain': {
                            'amountApprovedProjects': 'Cantidad de proyectos aprobados',
                            'amountApprovedProjectsInfo': 'Muestra la cantidad de proyectos aprobados y no aprobados.',
                            'amountApprovedProjectsByYear': 'Cantidad de proyectos aprobados por año',
                            'amountApprovedProjectsByYearInfo': 'Muestra la cantidad de proyectos aprobados y no aprobados por año.',
                            'amountApprovedProjectStatuses': 'Cantidad de estados de proyectos aprobados',
                            'amountApprovedProjectStatusesInfo': 'Muestra la Cantidad de estados de proyectos aprobados.',
                            'amountApprovedProjectStatusesByYear': 'Cantidad de estados de proyectos aprobados por año',
                            'amountApprovedProjectStatusesByYearInfo': 'Muestra la Cantidad de estados de proyectos aprobados por año.',
                            'durationComparisonByMonth': 'Comparación de la duración de los proyectos por mes',
                            'durationComparisonByMonthInfo': 'Realiza una comparación entre la cantidad de proyectos cuya duración real planteada es X meses vs la duración planteada original.',
                            'amountProjectsByType': 'Cantidad de proyectos por tipología',
                            'amountProjectsByTypeInfo': 'Muestra la cantidad de proyectos aprobados por tipología, al hacer clic sobre la o las etiquetas deseadas se podrá ocultar o mostrar la fracción del disco correspondiente, asi mismo al posarse sobre la porción del disco se mostrará el numero que representa dicha fracción.',
                            'amountProjectsByTypePerYear': 'Cantidad de proyectos por tipología por año',
                            'amountProjectsByTypePerYearInfo': 'Cantidad de proyectos aprobados por tipología y por año, lo cual permitirá saber cual fue la tipología de proyecto predominante en determinado año.',
                            'leadersProportionByGender': 'Proporción líderes de proyectos por género',
                            'leadersProportionByGenderInfo': 'Proporción de proyectos aprobados por tipología y por año, lo cual permitirá saber cual fue la tipología de proyecto predominante en determinado año.',
                            'projectDirectorsByGender': 'Cantidad de directores de proyectos por género y tipología',
                            'projectDirectorsByGenderInfo': 'Cantidad de lideres de proyecto por genero y por tipologias de Investigación Básica e Investigación Aplicada.',
                            'generalCollaborations': 'Colaboraciones Generales',
                            'projectType': 'Tipo de proyecto',
                            'projectTypology': 'Tipología de proyecto',
                            'projectStatus': 'Estado de proyecto',
                            'projectAmount': 'Cantidad de proyectos',
                            'projectDurationMonths': 'Duración de proyectos (meses)',
                            'durationType': 'Tipo de duración',
                            'typologyType': 'Tipo de tipología',
                            'directorsAmount': 'Cantidad de directores'
                        },
                        'productsMain': {
                            'amountPublicationsByType': 'Cantidad de publicaciones por tipo',
                            'amountPublicationsByTypeInfo': 'En esta tabla se observa el listado de tipo de publicaciones con su correspondiente frecuencia de menor a mayor, entre sus características mas relevantes se encuentra la desgregación de la estadística por genero y la descarga del archivo CSV con la información requerida.',
                            'amountPublicationsByTypeAndYear': 'Cantidad de publicaciones por tipo y año',
                            'amountPublicationsByTypeAndYearInfo': 'Cantidad de publicaciones por tipo y por año, lo cual permitirá saber cual fue el tipo de producto predominante en determinado año, tiene filtro de genero y opción de descargar de los datos.',
                            'articleRate': 'Tasa de artículos',
                            'articleRateInfo': 'Representa el promedio de artículos por docente en determinado año. Se tiene en cuenta solamente los profesores activos para el año consultado.',
                            'amountPublicationsNationalAndInternational': 'Cantidad de publicaciones a nivel nacional e internacional',
                            'amountPublicationsNationalAndInternationalInfo': 'Presenta la cantidad de artículos publicados en revistas nacionales e internacionales para cada año.',
                            'scimagoRankingOverTime': 'Clasificación de ranking Scimago en el tiempo',
                            'scimagoRankingOverTimeInfo': 'El ranking de Scimago califica cada revista según su ISSN con un valor de 0 en adelante en cada año medido. Por tanto el indicador muestra el volumen de la calificación Scimago en el correspondiente tiempo según la escala definida para agrupar esta misma',
                            'topPublicationsByJournal': 'Top publicaciones por revista y/o conferencia',
                            'topPublicationsByJournalInfo': 'Muestra el ranking de las revistas y conferencias en las cuales los docentes publican sus trabajos.',
                            'topPublicationsByInstitution': 'Top publicaciones por institución',
                            'topPublicationsByInstitutionInfo': 'Muestra el ranking de instituciones con las cuales la universidad tiene más colaboraciones en la realización y publicaciones de productos de investigación.',
                            'amountCollaborationsByCountry': 'Cantidad de colaboraciones por país',
                            'amountCollaborationsByCountryInfo': 'Permite ver la cantidad de colaboraciones con otros países, al posarse sobre un país este se resaltará y se mostrará la cantidad de publicaciones hechas en conjunto con investigadores y/o instituciones del país determinado.',
                            'articleRatePublishedBy': 'Tasa de artículos publicados por ',
                            'articleRatePublishedByInfo': 'Realiza un análisis comparativo de las tasas de artículos por cada subnivel de la estructura organizacional. Por ejemplo, si se consulta una facultad, se puede ver la comparación de la tasa de artículos de cada uno de sus uab.',
                            'productionBy': 'Producción por ',
                            'productionByInfo': 'Permite ver una comparación de número de publicaciones y citaciones de las unidades correspondientes al siguiente nivel jerárquico, es decir, si se está visualizando una facultad mostrará la comparación de los uab adscritos a esta facultad, si es una sede la comparación de las facultades.',
                            'amountThesisDirectors': 'Directores de tesis por género (Cantidad y Proporción)',
                            'amountThesisDirectorsInfo': 'Mujeres y hombres que dirigen tesis de pregrado, especialización, maestría y doctorado.',
                            'publicationType': 'Tipo de publicación',
                            'modalityType': 'Tipo de modalidad',
                            'amountDirectorsQuantity': 'Cantidad de directores y docentes',
                            'amountDirectorsProportion': 'Proporción de directores y docentes',
                            'publicationsAndCitations': 'Productos y Citaciones',
                            'productionType': 'Tipo de producción',
                            'collaborationsAmount': 'Cantidad de colaboraciones',
                            'amountPublicationsByScimago': 'Cantidad de publicaciones por clasificación Scimago'
                        },
                        'areasMain': {
                            'wordCloudType': 'Palabras',
                            'wordCloudValue': 'Cantidad'
                        }
                    },
                    'extension': {
                        'generalInformation': 'Información General',
                        'projects': 'Proyectos',
                        'opportunities': 'Oportunidades',
                        'activities': 'Actividades',
                        'consultingInfoInstitution': 'Consultando información de la institución.',
                        'consultingInfoGeneral': 'Consultando información general.',
                        'consultingInfoProjects': 'Consultando información de proyectos.',
                        'consultingInfoOpportunities': 'Consultando información de oportunidades.',
                        'consultingInfoActivities': 'Consultando información de actividades.',
                        'generalMain': {
                            'projectsCost': 'Costos de proyectos',
                            'projectsCostInfo': '',
                            'projectsCostNotice': 'Los datos de la gráfica corresponden a Educación Continua y Permanente',
                            'projectsCostValue': 'Costos',
                            'projectsCostAxisY': 'Costos en miles de millones de pesos COP',
                            'projectEarnings': 'Ganancias de proyectos',
                            'projectEarningsInfo': '',
                            'projectEarningsNotice': 'Los datos de la gráfica corresponden a Servicios Académicos y Educación Continua y Permanente',
                            'projectEarningsType': 'Modalidad',
                            'projectEarningsValue': 'Ganancias',
                            'projectEarningsAxisY': 'Ganancias en miles de millones de pesos COP',
                            'contractedEntities': 'Entidades contratadas',
                            'contractedEntitiesInfo': '',
                            'contractedEntitiesNotice': 'Los datos de la gráfica corresponden a Servicios Académicos',
                            'contractedEntitiesType': 'Entidades',
                            'contractedEntitiesValue': 'Cantidad de contrataciones',
                            'valueContractedEntities': 'Valor de entidades contratadas',
                            'valueContractedEntitiesInfo': '',
                            'valueContractedEntitiesNotice': 'Los datos de la gráfica corresponden a Servicios Académicos',
                            'valuecontractedEntitiesType': 'Entidades',
                            'valuecontractedEntitiesValue': 'Valor de contrataciones',
                            'educationActivities': 'Actividades de educación continua por región',
                            'educationActivitiesInfo': '',
                            'educationActivitiesNotice': 'Los datos de la gráfica corresponden a Educación Continua y Permanente',
                            'educationActivitiesType': 'Región',
                            'educationActivitiesValue': 'Actividades'
                        },
                        'projectsMain': {
                            'numberProjects': 'Cantidad de proyectos',
                            'numberProjectsInfo': '',
                            'numberProjectsNotice': 'Los datos de la gráfica corresponden a Servicios Académicos',
                            'numberProjectsValue': 'Cantidad de proyectos',
                            'numberProjectsAxisY': 'Cantidad de proyectos',
                            'valueProjects': 'Valor de los proyectos',
                            'valueProjectsInfo': '',
                            'valueProjectsNotice': 'Los datos de la gráfica corresponden a Servicios Académicos',
                            'valueProjectsValue': 'Valor',
                            'valueProjectsAxisY': 'Valor de los proyectos en miles de millones de pesos COP',
                            'classificationProjects': 'Clasificación de la naturaleza de los proyectos',
                            'classificationProjectsInfo': '',
                            'classificationProjectsNotice': 'Los datos de la gráfica corresponden a Servicios Académicos',
                            'classificationProjectsType': 'Clasificación',
                            'classificationProjectsValue': 'Cantidad de proyectos',
                            'classificationProjectsAxisY': 'Cantidad de proyectos',
                            'managerProjects': 'Directores de proyectos de extensión',
                            'managerProjectsInfo': '',
                            'managerProjectsNotice': 'Los datos de la gráfica corresponden a Servicios Académicos',
                            'managerProjectsType': 'Directores',
                            'managerProjectsValue': 'Cantidad',
                            'origenProjects': 'Origen de las propuestas de los proyectos',
                            'origenProjectsInfo': '',
                            'origenProjectsNotice': 'Los datos de la gráfica corresponden a Educación Continua y Permanente',
                            'origenProjectsType': 'Tipo',
                            'origenProjectsValue': 'Cantidad',
                            'wordCloudProjects': 'Nube de Palabras',
                            'wordCloudProjectsInfo': 'Se ofrecen 3 categorías de nubes de palabras: Los temas de primer nivel son aquellos que denotan categorías grandes de vocabularios controlados como Mesh para medicina o IEEE para ingenieria, cada categoria puede abarcar multiples terminos mas especificos que pertenecen a la segunda nube de palabras, temas de segundo nivel. Por ultimo se ofrece una nube general la cual consigna palabras clave que representa el trabajo realizado por un profesor, uab o facultad.',
                            'wordCloudProjectsNotice': ''
                        },
                        'opportunitiesMain': {
                            'statusComparison': 'Comparación de estados de oportunidades',
                            'statusComparisonInfo': '',
                            'statusComparisonNotice': '',
                            'statusComparisonType': 'Tipo de estado',
                            'statusComparisonValue': 'Cantidad de oportunidades',
                            'statusComparisonAxisY': 'Cantidad de oportunidades'
                        },
                        'activitiesMain': {
                            'numberActivities': 'Cantidad de actividades por periodo de tiempo',
                            'numberActivitiesInfo': '',
                            'numberActivitiesNotice': 'Los datos de la gráfica corresponden a Educación Continua y Permanente',
                            'numberActivitiesGraphValue': 'Cantidad de actividades',
                            'numberActivitiesGraphAxisY': 'Cantidad de actividades',
                            'numberParticipants': 'Cantidad de participantes por periodo de tiempo',
                            'numberParticipantsInfo': '',
                            'numberParticipantsNotice': 'Los datos de la gráfica corresponden a Educación Continua y Permanente',
                            'numberParticipantsGraphValue': 'Cantidad de participantes',
                            'numberParticipantsGraphAxisY': 'Cantidad de participantes',
                            'numberTopology': 'Cantidad de actividades realizadas según la tipología ECP',
                            'numberTopologyInfo': '',
                            'numberTopologyNotice': 'Los datos de la gráfica corresponden a Educación Continua y Permanente',
                            'numberTopologyType': 'Tipología ECP',
                            'numberTopologyValue': 'Cantidad de actividades',
                            'numberTopologyGraphAxisY': 'Cantidad de actividades',
                            'hourlyIntensity': 'Descripción de la intensidad horaria de cada submodalidad',
                            'hourlyIntensityInfo': '',
                            'hourlyIntensityNotice': 'Los datos de la gráfica corresponden a Educación Continua y Permanente',
                            'hourlyIntensityGraphValue': 'Intensidad horaria',
                            'hourlyIntensityGraphAxisY': 'Intensidad horaria',
                            'participantsSubModality': 'Cantidad de participantes por submodalidad',
                            'participantsSubModalityInfo': '',
                            'participantsSubModalityNotice': 'Los datos de la gráfica corresponden a Educación Continua y Permanente',
                            'participantsSubModalityType': 'Tipo de submodalidad',
                            'participantsSubModalityValue': 'Cantidad de participantes',
                            'participantsSubModalityAxisY': 'Cantidad de participantes',
                            'numberSubModality': 'Cantidad de actividades por submodalidad',
                            'numberSubModalityInfo': '',
                            'numberSubModalityNotice': 'Los datos de la gráfica corresponden a Educación Continua y Permanente',
                            'numberSubModalityType': 'Tipo de submodalidad',
                            'numberSubModalityValue': 'Cantidad de actividades',
                            'numberSubModalityAxisY': 'Cantidad de actividades'
                        }
                    }
                },
                'reports': {
                    'consultingInfoSearch': 'Consultando información.',
                    'buttonPanel': 'Panel lateral izquierdo',
                    'infoPanelTrue': 'Por favor presione para abrir el panel lateral izquierdo y seleccione uno de los reportes.',
                    'infoPanelFalse': 'Por favor seleccione uno de los reportes en el panel lateral izquierdo.',
                    'requestUrl': 'Url de la solicitud',
                    'copyClipboardUrl': 'Copiar al portapapeles el enlace de la petición',
                    'topic': 'Tema',
                    'institution': 'Institución',
                    'campus': 'Sede',
                    'faculty': 'Facultad',
                    'uab': 'Uab',
                    'identifier': 'Identificador',
                    'email': 'Correo electrónico',
                    'gender': 'Género',
                    'bonding': 'Vinculación',
                    'copyClipboard': 'Copiar al portapapeles la información del reporte',
                    'downloadReportJSON': 'Descargar el reporte en formato Json',
                    'downloadReportEXCEL': 'Descargar el reporte en formato EXCEL'
                }
            }
        }
    },
    LANGUAGE_ES: {
        'bs': 'Bosnia',
        'tr': 'Turco',
        'bg': 'Búlgaro',
        'sv': 'Sueco',
        'ro': 'Rumania',
        'sl': 'Esloveno',
        'tl': 'Tagalo',
        'el': 'Griego Moderno',
        'lv': 'Letonia',
        'id': 'Indonesio',
        'sw': 'Swahili',
        'zh-CN': 'Chino',
        'es': 'Español',
        'eo': 'Esperanto',
        'hr': 'Croacia',
        'mi': 'Maori',
        'af': 'Africanos',
        'cs': 'Checo',
        'uz': 'Uzbeko',
        'cy': 'Galés',
        'vi': 'Vietnamita',
        'ru': 'Ruso',
        'pl': 'Polaco',
        'pt': 'Portugués',
        'sq': 'Albania',
        'lb': 'Luxemburgués',
        'la': 'Latin',
        'sm': 'Samoa',
        'fr': 'Francés',
        'ca': 'Catalán',
        'zu': 'Zulu',
        'eu': 'Vasco',
        'so': 'Somalí',
        'et': 'Estonia',
        'hu': 'Húngaro',
        'sk': 'Eslovaca',
        'en': 'Inglés',
        'nl': 'Holandés',
        'gd': 'Gaélico',
        'it': 'Italiano',
        'fi': 'Finlandés',
        'hi': 'Hindi',
        'de': 'Alemán',
        'gl': 'Galicia',
        'ms': 'Malayo',
        'mg': 'Madagascar',
        'no': 'Noruego',
        'da': 'Danés',
        'lt': 'Lituano',
        'co': 'Corso',
    },
    LANGUAGE_EN: {
        'bs': 'Bosnia',
        'tr': 'Turco',
        'bg': 'Búlgaro',
        'sv': 'Sueco',
        'ro': 'Rumania',
        'sl': 'Esloveno',
        'tl': 'Tagalo',
        'el': 'Griego Moderno',
        'lv': 'Letonia',
        'id': 'Indonesio',
        'sw': 'Swahili',
        'zh-CN': 'Chino',
        'es': 'Spanish',
        'eo': 'Esperanto',
        'hr': 'Croacia',
        'mi': 'Maori',
        'af': 'Africanos',
        'cs': 'Checo',
        'uz': 'Uzbeko',
        'cy': 'Galés',
        'vi': 'Vietnamita',
        'ru': 'Ruso',
        'pl': 'Polaco',
        'pt': 'Portugués',
        'sq': 'Albania',
        'lb': 'Luxemburgués',
        'la': 'Latin',
        'sm': 'Samoa',
        'fr': 'Francés',
        'ca': 'Catalán',
        'zu': 'Zulu',
        'eu': 'Vasco',
        'so': 'Somalí',
        'et': 'Estonia',
        'hu': 'Húngaro',
        'sk': 'Eslovaca',
        'en': 'English',
        'nl': 'Holandés',
        'gd': 'Gaélico',
        'it': 'Italiano',
        'fi': 'Finlandés',
        'hi': 'Hindi',
        'de': 'Alemán',
        'gl': 'Galicia',
        'ms': 'Malayo',
        'mg': 'Madagascar',
        'no': 'Noruego',
        'da': 'Danés',
        'lt': 'Lituano',
        'co': 'Corso',
    },
    SEMESTER_TYPE: {
        first: 'I',
        second: 'II',
    },
    OPPORTUNITIES_TYPE: {
        count: 'Oportunidades',
        to_prop: 'Propuestas',
        to_proj: 'Proyectos'
    },
    // LOGIN FIELDS
    LOGIN_ID_FIELD: {
        field: '_id',
        label: 'Identificador',
    },
    LOGIN_USER_FIELD: {
        field: 'user',
        label: 'Usuario',
    },
    LOGIN_PASS_FIELD: {
        field: 'password',
        label: 'Contraseña',
    },
    LOGIN_NAMES_FIELD: {
        field: 'names',
        label: 'Nombres',
    },
    LOGIN_SURNAMES_FIELD: {
        field: 'surnames',
        label: 'Apellidos',
    },
    LOGIN_ROLE_FIELD: {
        field: 'dependence',
        label: 'Rol',
    },
    // FEEDBACK FIELDS
    FEEDBACK_DATE_FIELD: 'date',
    FEEDBACK_USER_FIELD: 'user',
    FEEDBACK_USER_NO_FIELD: 'noUser',
    FEEDBACK_USER_INFO_FIELD: 'userInfo',
    FEEDBACK_USER_NO_NAME_FIELD: 'name',
    FEEDBACK_USER_NO_EMAIL_FIELD: 'email',
    FEEDBACK_USER_NO_POSITION_FIELD: 'relation',
    // USABILITY FIELDS
    USABILITY_ID_FIELD: '_id',
    USABILITY_DATE_FIELD: 'date',
    USABILITY_INFO_FIELD: 'infoClient',
    USABILITY_TRACE_FIELD: 'trace',
    USABILITY_RECORD_FIELD: 'record',
    // NEWS FIELDS
    NEWS_TITLE_FIELD: 'title_',
    NEWS_DESCRIPTION_FIELD: 'description_',
    NEWS_ID_FIELD: {
        field: '_id',
        label: 'Identificador',
    },
    NEWS_TITLE_ENGLISH_FIELD: {
        field: 'title_en',
        label: 'Título en ingles',
    },
    NEWS_TITLE_SPANISH_FIELD: {
        field: 'title_es',
        label: 'Título en español',
    },
    NEWS_DESCRIPTION_ENGLISH_FIELD: {
        field: 'description_en',
        label: 'Descripción en ingles',
    },
    NEWS_DESCRIPTION_SPANISH_FIELD: {
        field: 'description_es',
        label: 'Descripción en español',
    },
    NEWS_DATE_FIELD: {
        field: 'date',
        label: 'Fecha',
    },
    NEWS_IMAGE_FIELD: {
        field: 'image',
        label: 'Imagen',
    },
    NEWS_IMAGE64_FIELD: {
        field: 'image64',
        label: 'Imagen',
    },
    NEWS_TYPE_FIELD: {
        field: 'type',
        label: 'Tipo',
    },
    REPORTS: [
        {
            id: 'gender-report',
            label_es: 'Reportes de genero',
            label_en: 'Gender reports',
            children: [
                {
                    id: 'gender',
                    label_es: 'Género',
                    label_en: 'Gender',
                    subLabel: 'Reportes de genero',
                    type: 'GET',
                    url: `${URL_API_INV}/reports/gender`,
                    disabled: {
                        tema: false,
                        institucion: true,
                        sede: true,
                        facultad: true,
                        departamento: true,
                        _id: false,
                        correo: false,
                        genero: false,
                        vinculacion: false
                    },
                },
                {
                    id: 'gender-by-age',
                    label_es: 'Género por edad',
                    label_en: 'Gender by age',
                    subLabel: 'Reportes de genero',
                    type: 'GET',
                    url: `${URL_API_INV}/reports/gender-by-age`,
                    disabled: {
                        tema: false,
                        institucion: true,
                        sede: true,
                        facultad: true,
                        departamento: true,
                        _id: false,
                        correo: false,
                        genero: false,
                        vinculacion: false
                    },
                },
                {
                    id: 'leaders',
                    label_es: 'Líderes',
                    label_en: 'Leaders',
                    subLabel: 'Reportes de genero',
                    children: [
                        {
                            id: 'gender-leaders',
                            label_es: 'Líderes',
                            label_en: 'Leaders',
                            subLabel: 'Reportes de genero',
                            type: 'GET',
                            url: `${URL_API_INV}/reports/gender-leaders`,
                            disabled: {
                                tema: false,
                                institucion: true,
                                sede: true,
                                facultad: true,
                                departamento: true,
                                _id: false,
                                correo: false,
                                genero: false,
                                vinculacion: false
                            },
                        },
                        {
                            id: 'gender-leaders-inside',
                            label_es: 'Líderes internos',
                            label_en: 'Internal leaders',
                            subLabel: 'Reportes de genero',
                            type: 'GET',
                            url: `${URL_API_INV}/reports/gender-leaders-inside`,
                            disabled: {
                                tema: false,
                                institucion: true,
                                sede: true,
                                facultad: true,
                                departamento: true,
                                _id: false,
                                correo: false,
                                genero: false,
                                vinculacion: false
                            },
                        },
                        {
                            id: 'gender-leaders-outside',
                            label_es: 'Líderes externos',
                            label_en: 'External leaders',
                            subLabel: 'Reportes de genero',
                            type: 'GET',
                            url: `${URL_API_INV}/reports/gender-leaders-outside`,
                            disabled: {
                                tema: false,
                                institucion: true,
                                sede: true,
                                facultad: true,
                                departamento: true,
                                _id: false,
                                correo: false,
                                genero: false,
                                vinculacion: false
                            },
                        },
                        {
                            id: 'gender-leaders-national',
                            label_es: 'Líderes nacionales',
                            label_en: 'National leaders',
                            subLabel: 'Reportes de genero',
                            type: 'GET',
                            url: `${URL_API_INV}/reports/gender-leaders-national`,
                            disabled: {
                                tema: false,
                                institucion: true,
                                sede: true,
                                facultad: true,
                                departamento: true,
                                _id: false,
                                correo: false,
                                genero: false,
                                vinculacion: false
                            },
                        },
                        {
                            id: 'gender-leaders-international',
                            label_es: 'Líderes internacionales',
                            label_en: 'International leaders',
                            subLabel: 'Reportes de genero',
                            type: 'GET',
                            url: `${URL_API_INV}/reports/gender-leaders-international`,
                            disabled: {
                                tema: false,
                                institucion: true,
                                sede: true,
                                facultad: true,
                                departamento: true,
                                _id: false,
                                correo: false,
                                genero: false,
                                vinculacion: false
                            },
                        }
                    ],
                },
                {
                    id: 'thesis',
                    label_es: 'Tesis',
                    label_en: 'Thesis',
                    subLabel: 'Reportes de genero',
                    children: [
                        {
                            id: 'gender-thesis',
                            label_es: 'Tesis',
                            label_en: 'Thesis',
                            subLabel: 'Reportes de genero',
                            type: 'GET',
                            url: `${URL_API_INV}/reports/gender-thesis`,
                            disabled: {
                                tema: false,
                                institucion: true,
                                sede: true,
                                facultad: true,
                                departamento: true,
                                _id: false,
                                correo: false,
                                genero: false,
                                vinculacion: false
                            },
                        },
                        {
                            id: 'gender-thesis-undergraduate',
                            label_es: 'Tesis de pregrado',
                            label_en: 'Undergraduate thesis',
                            subLabel: 'Reportes de genero',
                            type: 'GET',
                            url: `${URL_API_INV}/reports/gender-thesis-undergraduate`,
                            disabled: {
                                tema: false,
                                institucion: true,
                                sede: true,
                                facultad: true,
                                departamento: true,
                                _id: false,
                                correo: false,
                                genero: false,
                                vinculacion: false
                            },
                        },
                        {
                            id: 'gender-thesis-doctor',
                            label_es: 'Tesis de doctorado',
                            label_en: 'Doctoral thesis',
                            subLabel: 'Reportes de genero',
                            type: 'GET',
                            url: `${URL_API_INV}/reports/gender-thesis-doctor`,
                            disabled: {
                                tema: false,
                                institucion: true,
                                sede: true,
                                facultad: true,
                                departamento: true,
                                _id: false,
                                correo: false,
                                genero: false,
                                vinculacion: false
                            },
                        },
                        {
                            id: 'gender-thesis-specialization',
                            label_es: 'Tesis de especialización',
                            label_en: 'Specialization thesis',
                            subLabel: 'Reportes de genero',
                            type: 'GET',
                            url: `${URL_API_INV}/reports/gender-thesis-specialization`,
                            disabled: {
                                tema: false,
                                institucion: true,
                                sede: true,
                                facultad: true,
                                departamento: true,
                                _id: false,
                                correo: false,
                                genero: false,
                                vinculacion: false
                            },
                        },
                        {
                            id: 'gender-thesis-master',
                            label_es: 'Tesis de maestría',
                            label_en: 'Master\'s Thesis',
                            subLabel: 'Reportes de genero',
                            type: 'GET',
                            url: `${URL_API_INV}/reports/gender-thesis-master`,
                            disabled: {
                                tema: false,
                                institucion: true,
                                sede: true,
                                facultad: true,
                                departamento: true,
                                _id: false,
                                correo: false,
                                genero: false,
                                vinculacion: false
                            },
                        },
                    ],
                },
                {
                    id: 'types',
                    label_es: 'Tipos de proyectos',
                    label_en: 'Project types',
                    subLabel: 'Reportes de genero',
                    children: [
                        {
                            id: 'gender-types',
                            label_es: 'Tipos de proyectos',
                            label_en: 'Project types',
                            subLabel: 'Reportes de genero',
                            type: 'GET',
                            url: `${URL_API_INV}/reports/gender-types`,
                            disabled: {
                                tema: false,
                                institucion: true,
                                sede: true,
                                facultad: true,
                                departamento: true,
                                _id: false,
                                correo: false,
                                genero: false,
                                vinculacion: false
                            },
                        },
                        {
                            id: 'gender-types-basic',
                            label_es: 'Investigación básica',
                            label_en: 'Basic investigation',
                            subLabel: 'Reportes de genero',
                            type: 'GET',
                            url: `${URL_API_INV}/reports/gender-types-basic`,
                            disabled: {
                                tema: false,
                                institucion: true,
                                sede: true,
                                facultad: true,
                                departamento: true,
                                _id: false,
                                correo: false,
                                genero: false,
                                vinculacion: false
                            },
                        },
                        {
                            id: 'gender-types-applies',
                            label_es: 'Investigación aplicada',
                            label_en: 'Applied research',
                            subLabel: 'Reportes de genero',
                            type: 'GET',
                            url: `${URL_API_INV}/reports/gender-types-applies`,
                            disabled: {
                                tema: false,
                                institucion: true,
                                sede: true,
                                facultad: true,
                                departamento: true,
                                _id: false,
                                correo: false,
                                genero: false,
                                vinculacion: false
                            },
                        },
                    ],
                },
                // {
                //   id: 'cost',
                //   label_es: 'Costos',
                //   label_en: 'Costs',
                //   subLabel: 'Reportes de genero',
                //   children: [
                //     {
                //       id: 'gender-project-cost',
                //       label_es: 'Costos de proyectos',
                //       label_en: 'Project costs',
                //       subLabel: 'Reportes de genero',
                //       type: 'GET',
                //       url: `${URL_API_INV}/reports/gender-project-cost`,
                //       disabled: {
                //         tema: false,
                //         institucion: true,
                //         sede: true,
                //         facultad: true,
                //         departamento: true,
                //         _id: false,
                //         correo: false,
                //         genero: false,
                //         vinculacion: false
                //       },
                //     },
                //     {
                //       id: 'gender-project-cost-ranged',
                //       label_es: 'Costos de proyectos por rango',
                //       label_en: 'Project costs by range',
                //       subLabel: 'Reportes de genero',
                //       type: 'GET',
                //       url: `${URL_API_INV}/reports/gender-project-cost-ranged`,
                //       disabled: {
                //         tema: false,
                //         institucion: true,
                //         sede: true,
                //         facultad: true,
                //         departamento: true,
                //         _id: false,
                //         correo: false,
                //         genero: false,
                //         vinculacion: false
                //       },
                //     },
                //   ],
                // }
            ],
        },
        {
            id: 'authors-report',
            label_es: 'Reporte de autores',
            label_en: 'Authors report',
            children: [
                {
                    id: 'authors',
                    label_es: 'Autores',
                    label_en: 'Authors',
                    subLabel: 'Reporte de autores',
                    type: 'GET',
                    url: `${URL_API_INV}/reports/authors`,
                    disabled: {
                        tema: false,
                        institucion: true,
                        sede: true,
                        facultad: true,
                        departamento: true,
                        _id: false,
                        correo: false,
                        genero: true,
                        vinculacion: true
                    }
                },
                {
                    id: 'authors-publications',
                    label_es: 'Publicaciones',
                    label_en: 'Publications',
                    subLabel: 'Reporte de autores',
                    type: 'GET',
                    url: `${URL_API_INV}/reports/authors-publications`,
                    disabled: {
                        tema: false,
                        institucion: false,
                        sede: false,
                        facultad: false,
                        departamento: false,
                        _id: true,
                        correo: true,
                        genero: false,
                        vinculacion: false
                    },
                },
                {
                    id: 'authors-digital-transformation',
                    label_es: 'Autores transformación digital',
                    label_en: 'Authors digital transformation',
                    subLabel: 'Reporte de autores',
                    type: 'GET',
                    url: `${URL_API_INV}/reports/authors-digital-transformation`,
                    disabled: {
                        tema: false,
                        institucion: true,
                        sede: true,
                        facultad: true,
                        departamento: true,
                        _id: false,
                        correo: false,
                        genero: false,
                        vinculacion: false
                    },
                }
            ]
        },
        {
            id: 'search',
            label_es: 'Buscador',
            label_en: 'Search',
            children: [
                {
                    id: 'topics-search',
                    label_es: 'Buscador de temas',
                    label_en: 'Topics search',
                    subLabel: 'Buscador de temas',
                    type: 'GET',
                    url: `${URL_API_INV}/reports/search`,
                    disabled: {
                        tema: true,
                        institucion: false,
                        sede: true,
                        facultad: true,
                        departamento: true,
                        _id: false,
                        correo: false,
                        genero: true,
                        vinculacion: true
                    }
                }
            ]
        }
    ],
    CURATOR_COUNTRIES: [
        { value: 'Angola', label: 'Angola' },
        { value: 'Argelia', label: 'Argelia' },
        { value: 'Benin', label: 'Benin' },
        { value: 'Botsuana', label: 'Botsuana' },
        { value: 'Burkina Faso', label: 'Burkina Faso' },
        { value: 'Burundi', label: 'Burundi' },
        { value: 'Cabo Verde', label: 'Cabo Verde' },
        { value: 'Camerún', label: 'Camerún' },
        { value: 'Chad', label: 'Chad' },
        { value: 'Comoras', label: 'Comoras' },
        { value: 'Costa De Marfil', label: 'Costa De Marfil' },
        { value: 'Egipto', label: 'Egipto' },
        { value: 'Eritrea', label: 'Eritrea' },
        { value: 'Etiopía', label: 'Etiopía' },
        { value: 'Gabón', label: 'Gabón' },
        { value: 'Gambia', label: 'Gambia' },
        { value: 'Ghana', label: 'Ghana' },
        { value: 'Guinea', label: 'Guinea' },
        { value: 'Guinea Ecuatorial', label: 'Guinea Ecuatorial' },
        { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
        { value: 'Kenia', label: 'Kenia' },
        { value: 'Lesoto', label: 'Lesoto' },
        { value: 'Liberia', label: 'Liberia' },
        { value: 'Libia', label: 'Libia' },
        { value: 'Madagascar', label: 'Madagascar' },
        { value: 'Malaui', label: 'Malaui' },
        { value: 'Mali', label: 'Mali' },
        { value: 'Marruecos', label: 'Marruecos' },
        { value: 'Mauricio', label: 'Mauricio' },
        { value: 'Mauritania', label: 'Mauritania' },
        { value: 'Mozambique', label: 'Mozambique' },
        { value: 'Namibia', label: 'Namibia' },
        { value: 'Níger', label: 'Níger' },
        { value: 'Nigeria', label: 'Nigeria' },
        { value: 'República Centroafricana', label: 'República Centroafricana' },
        { value: 'República Del Congo', label: 'República Del Congo' },
        {
            value: 'República Democrática Del Congo',
            label: 'República Democrática Del Congo',
        },
        { value: 'Ruanda', label: 'Ruanda' },
        { value: 'Santo Tomé Y Príncipe', label: 'Santo Tomé Y Príncipe' },
        { value: 'Senegal', label: 'Senegal' },
        { value: 'Seychelles', label: 'Seychelles' },
        { value: 'Sierra Leona', label: 'Sierra Leona' },
        { value: 'Somalia', label: 'Somalia' },
        { value: 'Suazilandia', label: 'Suazilandia' },
        { value: 'Sudáfrica', label: 'Sudáfrica' },
        { value: 'Sudán', label: 'Sudán' },
        { value: 'Sudán Del Sur', label: 'Sudán Del Sur' },
        { value: 'Tanzania', label: 'Tanzania' },
        { value: 'Togo', label: 'Togo' },
        { value: 'Túnez', label: 'Túnez' },
        { value: 'Uganda', label: 'Uganda' },
        { value: 'Yibuti', label: 'Yibuti' },
        { value: 'Zambia', label: 'Zambia' },
        { value: 'Zimbabue', label: 'Zimbabue' },
        { value: 'Antigua Y Barbuda', label: 'Antigua Y Barbuda' },
        { value: 'Argentina', label: 'Argentina' },
        { value: 'Bahamas', label: 'Bahamas' },
        { value: 'Barbados', label: 'Barbados' },
        { value: 'Belice', label: 'Belice' },
        { value: 'Bolivia', label: 'Bolivia' },
        { value: 'Brasil', label: 'Brasil' },
        { value: 'Canadá', label: 'Canadá' },
        { value: 'Chile', label: 'Chile' },
        { value: 'Colombia', label: 'Colombia' },
        { value: 'Costa Rica', label: 'Costa Rica' },
        { value: 'Cuba', label: 'Cuba' },
        { value: 'Dominica', label: 'Dominica' },
        { value: 'Ecuador', label: 'Ecuador' },
        { value: 'El Salvador', label: 'El Salvador' },
        { value: 'Estados Unidos', label: 'Estados Unidos' },
        { value: 'Granada', label: 'Granada' },
        { value: 'Guatemala', label: 'Guatemala' },
        { value: 'Guyana', label: 'Guyana' },
        { value: 'Haití', label: 'Haití' },
        { value: 'Honduras', label: 'Honduras' },
        { value: 'Jamaica', label: 'Jamaica' },
        { value: 'México', label: 'México' },
        { value: 'Nicaragua', label: 'Nicaragua' },
        { value: 'Panamá', label: 'Panamá' },
        { value: 'Paraguay', label: 'Paraguay' },
        { value: 'Perú', label: 'Perú' },
        { value: 'Puerto Rico', label: 'Puerto Rico' },
        { value: 'República Dominicana', label: 'República Dominicana' },
        { value: 'San Cristóbal Y Nieves', label: 'San Cristóbal Y Nieves' },
        {
            value: 'San Vicente Y Las Granadinas',
            label: 'San Vicente Y Las Granadinas',
        },
        { value: 'Santa Lucía', label: 'Santa Lucía' },
        { value: 'Surinam', label: 'Surinam' },
        { value: 'Trinidad Y Tobago', label: 'Trinidad Y Tobago' },
        { value: 'Uruguay', label: 'Uruguay' },
        { value: 'Venezuela', label: 'Venezuela' },
        { value: 'Afganistán', label: 'Afganistán' },
        { value: 'Arabia Saudita', label: 'Arabia Saudita' },
        { value: 'Bangladés', label: 'Bangladés' },
        { value: 'Baréin', label: 'Baréin' },
        { value: 'Brunei', label: 'Brunei' },
        { value: 'Bután', label: 'Bután' },
        { value: 'Camboya', label: 'Camboya' },
        { value: 'Catar', label: 'Catar' },
        { value: 'China', label: 'China' },
        { value: 'Chipre', label: 'Chipre' },
        { value: 'Corea Del Norte', label: 'Corea Del Norte' },
        { value: 'Corea Del Sur', label: 'Corea Del Sur' },
        { value: 'Emiratos Arabes Unidos', label: 'Emiratos Arabes Unidos' },
        { value: 'Filipinas', label: 'Filipinas' },
        { value: 'India', label: 'India' },
        { value: 'Indonesia', label: 'Indonesia' },
        { value: 'Irán', label: 'Irán' },
        { value: 'Iraq', label: 'Iraq' },
        { value: 'Israel', label: 'Israel' },
        { value: 'Japón', label: 'Japón' },
        { value: 'Jordania', label: 'Jordania' },
        { value: 'Kazajistán', label: 'Kazajistán' },
        { value: 'Kirguistán', label: 'Kirguistán' },
        { value: 'Kuwait', label: 'Kuwait' },
        { value: 'Laos', label: 'Laos' },
        { value: 'Líbano', label: 'Líbano' },
        { value: 'Malasia', label: 'Malasia' },
        { value: 'Maldivas', label: 'Maldivas' },
        { value: 'Mongolia', label: 'Mongolia' },
        { value: 'Myanmar (Birmania)', label: 'Myanmar (Birmania)' },
        { value: 'Nepal', label: 'Nepal' },
        { value: 'Omán', label: 'Omán' },
        { value: 'Pakistán', label: 'Pakistán' },
        { value: 'Palestina', label: 'Palestina' },
        { value: 'Siria', label: 'Siria' },
        { value: 'Sri Lanka', label: 'Sri Lanka' },
        { value: 'Tailandia', label: 'Tailandia' },
        { value: 'Tayikistán', label: 'Tayikistán' },
        { value: 'Timor Oriental', label: 'Timor Oriental' },
        { value: 'Turkmenistán', label: 'Turkmenistán' },
        { value: 'Turquía', label: 'Turquía' },
        { value: 'Uzbekistán', label: 'Uzbekistán' },
        { value: 'Vietnam', label: 'Vietnam' },
        { value: 'Yemen', label: 'Yemen' },
        { value: 'Albania', label: 'Albania' },
        { value: 'Alemania', label: 'Alemania' },
        { value: 'Andorra', label: 'Andorra' },
        { value: 'Armenia', label: 'Armenia' },
        { value: 'Austria', label: 'Austria' },
        { value: 'Azerbaiyán', label: 'Azerbaiyán' },
        { value: 'Bélgica', label: 'Bélgica' },
        { value: 'Bielorrusia', label: 'Bielorrusia' },
        { value: 'Bosnia Y Herzegovina', label: 'Bosnia Y Herzegovina' },
        { value: 'Bulgaria', label: 'Bulgaria' },
        { value: 'Croacia', label: 'Croacia' },
        { value: 'Dinamarca', label: 'Dinamarca' },
        { value: 'Eslovaquia', label: 'Eslovaquia' },
        { value: 'Eslovenia', label: 'Eslovenia' },
        { value: 'España', label: 'España' },
        { value: 'Estonia', label: 'Estonia' },
        { value: 'Finlandia', label: 'Finlandia' },
        { value: 'Francia', label: 'Francia' },
        { value: 'Georgia', label: 'Georgia' },
        { value: 'Grecia', label: 'Grecia' },
        { value: 'Hungría', label: 'Hungría' },
        { value: 'Irlanda', label: 'Irlanda' },
        { value: 'Islandia', label: 'Islandia' },
        { value: 'Italia', label: 'Italia' },
        { value: 'Letonia', label: 'Letonia' },
        { value: 'Liechtenstein', label: 'Liechtenstein' },
        { value: 'Lituania', label: 'Lituania' },
        { value: 'Luxemburgo', label: 'Luxemburgo' },
        { value: 'Malta', label: 'Malta' },
        { value: 'Moldavia', label: 'Moldavia' },
        { value: 'Mónaco', label: 'Mónaco' },
        { value: 'Montenegro', label: 'Montenegro' },
        { value: 'Noruega', label: 'Noruega' },
        { value: 'Países Bajos', label: 'Países Bajos' },
        { value: 'Polonia', label: 'Polonia' },
        { value: 'Portugal', label: 'Portugal' },
        { value: 'Reino Unido', label: 'Reino Unido' },
        { value: 'República Checa', label: 'República Checa' },
        { value: 'República De Macedonia', label: 'República De Macedonia' },
        { value: 'Rumania', label: 'Rumania' },
        { value: 'Rusia', label: 'Rusia' },
        { value: 'San Marino', label: 'San Marino' },
        { value: 'Serbia', label: 'Serbia' },
        { value: 'Suecia', label: 'Suecia' },
        { value: 'Suiza', label: 'Suiza' },
        { value: 'Ucrania', label: 'Ucrania' },
        { value: 'Australia', label: 'Australia' },
        { value: 'Fiyi', label: 'Fiyi' },
        { value: 'Islas Marshall', label: 'Islas Marshall' },
        { value: 'Islas Salomón', label: 'Islas Salomón' },
        { value: 'Kiribati', label: 'Kiribati' },
        { value: 'Micronesia', label: 'Micronesia' },
        { value: 'Nauru', label: 'Nauru' },
        { value: 'Nueva Zelanda', label: 'Nueva Zelanda' },
        { value: 'Palaos', label: 'Palaos' },
        { value: 'Papúa Nueva Guinea', label: 'Papúa Nueva Guinea' },
        { value: 'Samoa', label: 'Samoa' },
        { value: 'Tonga', label: 'Tonga' },
        { value: 'Tuvalu', label: 'Tuvalu' },
        { value: 'Vanuatu', label: 'Vanuatu' },
    ],
    CURATOR_CITIES: [
        { value: 'Luanda', label: 'Luanda' },
        { value: 'Argel', label: 'Argel' },
        { value: 'Porto-Novo', label: 'Porto-Novo' },
        { value: 'Gaberones', label: 'Gaberones' },
        { value: 'Uagadugú', label: 'Uagadugú' },
        { value: 'Buyumbura', label: 'Buyumbura' },
        { value: 'Praia', label: 'Praia' },
        { value: 'Yaundé', label: 'Yaundé' },
        { value: 'Yamena', label: 'Yamena' },
        { value: 'Moroni', label: 'Moroni' },
        { value: 'Yamusukro, Abiyán', label: 'Yamusukro, Abiyán' },
        { value: 'El Cairo', label: 'El Cairo' },
        { value: 'Asmara', label: 'Asmara' },
        { value: 'Adís Abeba', label: 'Adís Abeba' },
        { value: 'Libreville', label: 'Libreville' },
        { value: 'Banjul', label: 'Banjul' },
        { value: 'Accra', label: 'Accra' },
        { value: 'Conakry', label: 'Conakry' },
        { value: 'Malabo', label: 'Malabo' },
        { value: 'Bissau', label: 'Bissau' },
        { value: 'Nairobi', label: 'Nairobi' },
        { value: 'Maseru', label: 'Maseru' },
        { value: 'Monrovia', label: 'Monrovia' },
        { value: 'Trípoli', label: 'Trípoli' },
        { value: 'Antananarivo', label: 'Antananarivo' },
        { value: 'Lilongüe', label: 'Lilongüe' },
        { value: 'Bamako', label: 'Bamako' },
        { value: 'Rabat', label: 'Rabat' },
        { value: 'Port Louis', label: 'Port Louis' },
        { value: 'Nuakchot', label: 'Nuakchot' },
        { value: 'Maputo', label: 'Maputo' },
        { value: 'Windhoek', label: 'Windhoek' },
        { value: 'Niamey', label: 'Niamey' },
        { value: 'Abuya', label: 'Abuya' },
        { value: 'Bangui', label: 'Bangui' },
        { value: 'Brazzaville', label: 'Brazzaville' },
        { value: 'Kinshasa', label: 'Kinshasa' },
        { value: 'Kigali', label: 'Kigali' },
        { value: 'Santo Tomé', label: 'Santo Tomé' },
        { value: 'Dakar', label: 'Dakar' },
        { value: 'Victoria', label: 'Victoria' },
        { value: 'Freetown', label: 'Freetown' },
        { value: 'Mogadiscio', label: 'Mogadiscio' },
        { value: 'Mbabane', label: 'Mbabane' },
        {
            value: 'Pretoria, Ciudad Del Cabo, Bloemfontein',
            label: 'Pretoria, Ciudad Del Cabo, Bloemfontein',
        },
        { value: 'Jartum', label: 'Jartum' },
        { value: 'Yuba', label: 'Yuba' },
        { value: 'Dodoma', label: 'Dodoma' },
        { value: 'Lomé', label: 'Lomé' },
        { value: 'Túnez', label: 'Túnez' },
        { value: 'Kampala', label: 'Kampala' },
        { value: 'Yibuti', label: 'Yibuti' },
        { value: 'Lusaka', label: 'Lusaka' },
        { value: 'Harare', label: 'Harare' },
        { value: 'Saint John\'S', label: 'Saint John\'S' },
        { value: 'Buenos Aires', label: 'Buenos Aires' },
        { value: 'Nassau', label: 'Nassau' },
        { value: 'Bridgetown', label: 'Bridgetown' },
        { value: 'Belmopán', label: 'Belmopán' },
        { value: 'Sucre, La Paz', label: 'Sucre, La Paz' },
        { value: 'Brasilia', label: 'Brasilia' },
        { value: 'Ottawa', label: 'Ottawa' },
        { value: 'Santiago De Chile', label: 'Santiago De Chile' },
        { value: 'Bogotá', label: 'Bogotá' },
        { value: 'San José', label: 'San José' },
        { value: 'La Habana', label: 'La Habana' },
        { value: 'Roseau', label: 'Roseau' },
        { value: 'Quito', label: 'Quito' },
        { value: 'San Salvador', label: 'San Salvador' },
        { value: 'Washington D. C.', label: 'Washington D. C.' },
        { value: 'Saint George\'S', label: 'Saint George\'S' },
        { value: 'Ciudad De Guatemala', label: 'Ciudad De Guatemala' },
        { value: 'Georgetown', label: 'Georgetown' },
        { value: 'Puerto Príncipe', label: 'Puerto Príncipe' },
        { value: 'Tegucigalpa', label: 'Tegucigalpa' },
        { value: 'Kingston', label: 'Kingston' },
        { value: 'México D. F.', label: 'México D. F.' },
        { value: 'Managua', label: 'Managua' },
        { value: 'Ciudad De Panamá', label: 'Ciudad De Panamá' },
        { value: 'Asunción', label: 'Asunción' },
        { value: 'Lima', label: 'Lima' },
        { value: 'San Juan', label: 'San Juan' },
        { value: 'Santo Domingo', label: 'Santo Domingo' },
        { value: 'Basseterre', label: 'Basseterre' },
        { value: 'Kingstown', label: 'Kingstown' },
        { value: 'Castries', label: 'Castries' },
        { value: 'Paramaribo', label: 'Paramaribo' },
        { value: 'Puerto España', label: 'Puerto España' },
        { value: 'Montevideo', label: 'Montevideo' },
        { value: 'Caracas', label: 'Caracas' },
        { value: 'Kabul', label: 'Kabul' },
        { value: 'Riad', label: 'Riad' },
        { value: 'Daca', label: 'Daca' },
        { value: 'Manamá', label: 'Manamá' },
        { value: 'Bandar Seri Begawan', label: 'Bandar Seri Begawan' },
        { value: 'Timbu', label: 'Timbu' },
        { value: 'Pnon Pehn', label: 'Pnon Pehn' },
        { value: 'Doha', label: 'Doha' },
        { value: 'Pekín', label: 'Pekín' },
        { value: 'Nicosia', label: 'Nicosia' },
        { value: 'Pyongyang', label: 'Pyongyang' },
        { value: 'Seúl', label: 'Seúl' },
        { value: 'Abu Dabi', label: 'Abu Dabi' },
        { value: 'Manila', label: 'Manila' },
        { value: 'Nueva Delhi', label: 'Nueva Delhi' },
        { value: 'Yakarta', label: 'Yakarta' },
        { value: 'Teherán', label: 'Teherán' },
        { value: 'Bagdad', label: 'Bagdad' },
        { value: 'Jerusalén', label: 'Jerusalén' },
        { value: 'Tokio', label: 'Tokio' },
        { value: 'Ammán', label: 'Ammán' },
        { value: 'Astaná', label: 'Astaná' },
        { value: 'Biskek', label: 'Biskek' },
        { value: 'Ciudad De Kuwait', label: 'Ciudad De Kuwait' },
        { value: 'Vientián', label: 'Vientián' },
        { value: 'Beirut', label: 'Beirut' },
        { value: 'Kuala Lumpur', label: 'Kuala Lumpur' },
        { value: 'Malé', label: 'Malé' },
        { value: 'Ulan Bator', label: 'Ulan Bator' },
        { value: 'Naypyidaw', label: 'Naypyidaw' },
        { value: 'Katmandú', label: 'Katmandú' },
        { value: 'Mascate', label: 'Mascate' },
        { value: 'Islamabad', label: 'Islamabad' },
        { value: 'Ramala', label: 'Ramala' },
        { value: 'Damasco', label: 'Damasco' },
        { value: 'Colombo', label: 'Colombo' },
        { value: 'Bangkok', label: 'Bangkok' },
        { value: 'Dusambé', label: 'Dusambé' },
        { value: 'Dili', label: 'Dili' },
        { value: 'Asjabad', label: 'Asjabad' },
        { value: 'Ankara', label: 'Ankara' },
        { value: 'Tashkent', label: 'Tashkent' },
        { value: 'Hanoi', label: 'Hanoi' },
        { value: 'Saná', label: 'Saná' },
        { value: 'Tirana', label: 'Tirana' },
        { value: 'Berlín', label: 'Berlín' },
        { value: 'Andorra La Vieja', label: 'Andorra La Vieja' },
        { value: 'Ereván', label: 'Ereván' },
        { value: 'Viena', label: 'Viena' },
        { value: 'Bakú', label: 'Bakú' },
        { value: 'Bruselas', label: 'Bruselas' },
        { value: 'Minsk', label: 'Minsk' },
        { value: 'Sarajevo', label: 'Sarajevo' },
        { value: 'Sofía', label: 'Sofía' },
        { value: 'Zagreb', label: 'Zagreb' },
        { value: 'Copenhague', label: 'Copenhague' },
        { value: 'Bratislava', label: 'Bratislava' },
        { value: 'Lublijana', label: 'Lublijana' },
        { value: 'Madrid', label: 'Madrid' },
        { value: 'Tallín', label: 'Tallín' },
        { value: 'Helsinki', label: 'Helsinki' },
        { value: 'París', label: 'París' },
        { value: 'Tiflis', label: 'Tiflis' },
        { value: 'Atenas', label: 'Atenas' },
        { value: 'Budapest', label: 'Budapest' },
        { value: 'Dublín', label: 'Dublín' },
        { value: 'Reikiavik', label: 'Reikiavik' },
        { value: 'Roma', label: 'Roma' },
        { value: 'Riga', label: 'Riga' },
        { value: 'Vaduz', label: 'Vaduz' },
        { value: 'Vilna', label: 'Vilna' },
        { value: 'Luxemburgo', label: 'Luxemburgo' },
        { value: 'La Valeta', label: 'La Valeta' },
        { value: 'Chisinau', label: 'Chisinau' },
        { value: 'Mónaco', label: 'Mónaco' },
        { value: 'Podgorica', label: 'Podgorica' },
        { value: 'Oslo', label: 'Oslo' },
        { value: 'Amsterdam', label: 'Amsterdam' },
        { value: 'Varsovia', label: 'Varsovia' },
        { value: 'Lisboa', label: 'Lisboa' },
        { value: 'Londres', label: 'Londres' },
        { value: 'Praga', label: 'Praga' },
        { value: 'Skopje', label: 'Skopje' },
        { value: 'Bucarest', label: 'Bucarest' },
        { value: 'Moscú', label: 'Moscú' },
        { value: 'Ciudad De San Marino', label: 'Ciudad De San Marino' },
        { value: 'Belgrado', label: 'Belgrado' },
        { value: 'Estocolmo', label: 'Estocolmo' },
        { value: 'Berna', label: 'Berna' },
        { value: 'Kiev', label: 'Kiev' },
        { value: 'Canberra', label: 'Canberra' },
        { value: 'Suva', label: 'Suva' },
        { value: 'Majuro', label: 'Majuro' },
        { value: 'Honiara', label: 'Honiara' },
        { value: 'Tarawa', label: 'Tarawa' },
        { value: 'Palikir', label: 'Palikir' },
        { value: 'Yaren', label: 'Yaren' },
        { value: 'Wellington', label: 'Wellington' },
        { value: 'Melekeok', label: 'Melekeok' },
        { value: 'Port Moresby', label: 'Port Moresby' },
        { value: 'Apia', label: 'Apia' },
        { value: 'Nuku\'Alofa', label: 'Nuku\'Alofa' },
        { value: 'Funafuti', label: 'Funafuti' },
        { value: 'Port Vila', label: 'Port Vila' },
    ],
    CURATOR_UNIVERSITIES: [
        { value: 'Boston University', label: 'Boston University' },
        { value: 'Brown University', label: 'Brown University' },
        {
            value: 'California Institute of Technology (Caltech)',
            label: 'California Institute of Technology (Caltech)',
        },
        {
            value: 'Carnegie Mellon University',
            label: 'Carnegie Mellon University',
        },
        {
            value: 'City University of Hong Kong',
            label: 'City University of Hong Kong',
        },
        {
            value: 'Colegio de Estudios Superiores de Administración',
            label: 'Colegio de Estudios Superiores de Administración',
        },
        {
            value: 'Colegio Mayor de Antioquia',
            label: 'Colegio Mayor de Antioquia',
        },
        { value: 'Columbia University', label: 'Columbia University' },
        { value: 'Conservatorio del Tolima', label: 'Conservatorio del Tolima' },
        { value: 'Cornell University', label: 'Cornell University' },
        {
            value: 'Corporacion Colegiatura Colombiana',
            label: 'Corporacion Colegiatura Colombiana',
        },
        {
            value: 'Corporacion Escuela de Artes y Letras',
            label: 'Corporacion Escuela de Artes y Letras',
        },
        {
            value: 'Corporacion Escuela Tecnologica del Oriente',
            label: 'Corporacion Escuela Tecnologica del Oriente',
        },
        {
            value: 'Corporacion Universidad de Investigacion y Desarrollo',
            label: 'Corporacion Universidad de Investigacion y Desarrollo',
        },
        {
            value: 'Corporación Universitaria Adventista',
            label: 'Corporación Universitaria Adventista',
        },
        {
            value: 'Corporacion Universitaria Americana',
            label: 'Corporacion Universitaria Americana',
        },
        {
            value: 'Corporacion Universitaria Antonio Jose de Sucre',
            label: 'Corporacion Universitaria Antonio Jose de Sucre',
        },
        {
            value: 'Corporacion Universitaria Autonoma de Nariño',
            label: 'Corporacion Universitaria Autonoma de Nariño',
        },
        {
            value: 'Corporacion Universitaria Autonoma del Cauca',
            label: 'Corporacion Universitaria Autonoma del Cauca',
        },
        {
            value: 'Corporacion Universitaria Centro Superior',
            label: 'Corporacion Universitaria Centro Superior',
        },
        {
            value: 'Corporacion Universitaria Comfacauca',
            label: 'Corporacion Universitaria Comfacauca',
        },
        {
            value: 'Corporacion Universitaria de Asturias',
            label: 'Corporacion Universitaria de Asturias',
        },
        {
            value: 'Corporacion Universitaria de Cataluña',
            label: 'Corporacion Universitaria de Cataluña',
        },
        {
            value: 'Corporacion Universitaria de Ciencia y Desarrollo',
            label: 'Corporacion Universitaria de Ciencia y Desarrollo',
        },
        {
            value:
                'Corporacion Universitaria de Ciencias Empresariales, Educacion y Salud',
            label:
                'Corporacion Universitaria de Ciencias Empresariales, Educacion y Salud',
        },
        {
            value: 'Corporacion Universitaria de Colombia Ideas',
            label: 'Corporacion Universitaria de Colombia Ideas',
        },
        {
            value: 'Corporación Universitaria de la Costa',
            label: 'Corporación Universitaria de la Costa',
        },
        {
            value: 'Corporacion Universitaria de Sabaneta',
            label: 'Corporacion Universitaria de Sabaneta',
        },
        {
            value: 'Corporacion Universitaria de Santa Rosa de Cabal',
            label: 'Corporacion Universitaria de Santa Rosa de Cabal',
        },
        {
            value: 'Corporacion Universitaria del Caribe',
            label: 'Corporacion Universitaria del Caribe',
        },
        {
            value: 'Corporacion Universitaria del Huila',
            label: 'Corporacion Universitaria del Huila',
        },
        {
            value: 'Corporacion Universitaria del Meta',
            label: 'Corporacion Universitaria del Meta',
        },
        {
            value: 'Corporacion Universitaria Empresarial Alexander Von Humboldt',
            label: 'Corporacion Universitaria Empresarial Alexander Von Humboldt',
        },
        {
            value: 'Corporacion Universitaria Empresarial de Salamanca',
            label: 'Corporacion Universitaria Empresarial de Salamanca',
        },
        {
            value: 'Corporacion Universitaria Iberoamericana',
            label: 'Corporacion Universitaria Iberoamericana',
        },
        {
            value: 'Corporación Universitaria Lasallista',
            label: 'Corporación Universitaria Lasallista',
        },
        {
            value: 'Corporacion Universitaria Latinoamericana',
            label: 'Corporacion Universitaria Latinoamericana',
        },
        {
            value: 'Corporación Universitaria Minuto de Dios',
            label: 'Corporación Universitaria Minuto de Dios',
        },
        {
            value: 'Corporacion Universitaria Rafael Nuñez',
            label: 'Corporacion Universitaria Rafael Nuñez',
        },
        {
            value: 'Corporacion Universitaria Reformada',
            label: 'Corporacion Universitaria Reformada',
        },
        {
            value: 'Corporacion Universitaria Remington',
            label: 'Corporacion Universitaria Remington',
        },
        {
            value: 'Corporacion Universitaria Republicana',
            label: 'Corporacion Universitaria Republicana',
        },
        {
            value: 'Corporacion Universitaria Taller Cinco',
            label: 'Corporacion Universitaria Taller Cinco',
        },
        {
            value: 'Corporacion Universitaria U de Colombia',
            label: 'Corporacion Universitaria U de Colombia',
        },
        {
            value: 'Corporación Universitaria Unitec',
            label: 'Corporación Universitaria Unitec',
        },
        {
            value: 'Delf University of Technology',
            label: 'Delf University of Technology',
        },
        { value: 'Duke University', label: 'Duke University' },
        { value: 'Durham University', label: 'Durham University' },
        {
            value: 'École normal supérieure, Paris',
            label: 'École normal supérieure, Paris',
        },
        { value: 'Ecole Polytechnique', label: 'Ecole Polytechnique' },
        {
            value: 'Ecole Polytechnique Fédérale de Lausanne (EPFL)',
            label: 'Ecole Polytechnique Fédérale de Lausanne (EPFL)',
        },
        {
            value:
                'Elite- Escuela Latinoamericana de Ingenieros, Tecnologos y Empresarios',
            label:
                'Elite- Escuela Latinoamericana de Ingenieros, Tecnologos y Empresarios',
        },
        {
            value: 'Escuela Colombiana de Ingenieria Julio Garavito',
            label: 'Escuela Colombiana de Ingenieria Julio Garavito',
        },
        {
            value: 'Escuela de Ingeniería de Antioquia',
            label: 'Escuela de Ingeniería de Antioquia',
        },
        {
            value: 'Escuela Nacional del Deporte',
            label: 'Escuela Nacional del Deporte',
        },
        {
            value: 'Escuela Superiore de Administración Pública',
            label: 'Escuela Superiore de Administración Pública',
        },
        {
            value: 'Escuela Tecnologica Instituto Tecnico Central',
            label: 'Escuela Tecnologica Instituto Tecnico Central',
        },
        {
            value: 'ETH Zurich – Swiss Federal Institute of Technology',
            label: 'ETH Zurich – Swiss Federal Institute of Technology',
        },
        { value: 'Fudan University', label: 'Fudan University' },
        {
            value: 'Fundacion Centro Universitario de Bienestar Rural',
            label: 'Fundacion Centro Universitario de Bienestar Rural',
        },
        {
            value:
                'Fundacion de Estudios Superiores Monseñor Abraham Escudero Montoya',
            label:
                'Fundacion de Estudios Superiores Monseñor Abraham Escudero Montoya',
        },
        {
            value:
                'Fundacion de Estudios Superiores Universitarios de Uraba Antonio Roldan Betancur',
            label:
                'Fundacion de Estudios Superiores Universitarios de Uraba Antonio Roldan Betancur',
        },
        {
            value: 'Fundacion Escuela Colombiana de Mercadotecnia',
            label: 'Fundacion Escuela Colombiana de Mercadotecnia',
        },
        {
            value: 'Fundacion Escuela Colombiana de Rehabilitacion',
            label: 'Fundacion Escuela Colombiana de Rehabilitacion',
        },
        {
            value: 'Fundacion Escuela Tecnologica de Neiva Jesus Oviedo Perez',
            label: 'Fundacion Escuela Tecnologica de Neiva Jesus Oviedo Perez',
        },
        {
            value: 'Fundacion Universitaria Agraria de Colombia',
            label: 'Fundacion Universitaria Agraria de Colombia',
        },
        {
            value: 'Fundacion Universitaria Autonoma de Las Americas',
            label: 'Fundacion Universitaria Autonoma de Las Americas',
        },
        {
            value: 'Fundacion Universitaria Bellas Artes',
            label: 'Fundacion Universitaria Bellas Artes',
        },
        {
            value: 'Fundacion Universitaria Cafam',
            label: 'Fundacion Universitaria Cafam',
        },
        {
            value: 'Fundacion Universitaria Catolica del Norte',
            label: 'Fundacion Universitaria Catolica del Norte',
        },
        {
            value: 'Fundacion Universitaria Catolica Lumen Gentium',
            label: 'Fundacion Universitaria Catolica Lumen Gentium',
        },
        {
            value: 'Fundacion Universitaria Ceipa',
            label: 'Fundacion Universitaria Ceipa',
        },
        {
            value: 'Fundación Universitaria Cervantes San Agustín',
            label: 'Fundación Universitaria Cervantes San Agustín',
        },
        {
            value: 'Fundacion Universitaria Claretiana',
            label: 'Fundacion Universitaria Claretiana',
        },
        {
            value: 'Fundacion Universitaria Colombo Internacional',
            label: 'Fundacion Universitaria Colombo Internacional',
        },
        {
            value: 'Fundacion Universitaria Comfamiliar Risaralda',
            label: 'Fundacion Universitaria Comfamiliar Risaralda',
        },
        {
            value: 'Fundacion Universitaria Comfenalco Santander',
            label: 'Fundacion Universitaria Comfenalco Santander',
        },
        {
            value: 'Fundación Universitaria Compensar',
            label: 'Fundación Universitaria Compensar',
        },
        {
            value: 'Fundacion Universitaria de Ciencias de La Salud',
            label: 'Fundacion Universitaria de Ciencias de La Salud',
        },
        {
            value: 'Fundacion Universitaria de Popayan',
            label: 'Fundacion Universitaria de Popayan',
        },
        {
            value: 'Fundacion Universitaria de San Gil',
            label: 'Fundacion Universitaria de San Gil',
        },
        {
            value: 'Fundación Universitaria del Area Andina',
            label: 'Fundación Universitaria del Area Andina',
        },
        {
            value:
                'Fundacion Universitaria Empresarial de La Camara de Comercio de Bogota',
            label:
                'Fundacion Universitaria Empresarial de La Camara de Comercio de Bogota',
        },
        {
            value: 'Fundacion Universitaria Horizonte',
            label: 'Fundacion Universitaria Horizonte',
        },
        {
            value: 'Fundacion Universitaria Internacional de Colombia',
            label: 'Fundacion Universitaria Internacional de Colombia',
        },
        {
            value: 'Fundacion Universitaria Internacional del Tropico Americano',
            label: 'Fundacion Universitaria Internacional del Tropico Americano',
        },
        {
            value: 'Fundacion Universitaria Juan de Castellanos',
            label: 'Fundacion Universitaria Juan de Castellanos',
        },
        {
            value: 'Fundación Universitaria Juan N. Corpas',
            label: 'Fundación Universitaria Juan N. Corpas',
        },
        {
            value: 'Fundacion Universitaria Konrad Lorenz',
            label: 'Fundacion Universitaria Konrad Lorenz',
        },
        {
            value: 'Fundacion Universitaria Luis G. Paez',
            label: 'Fundacion Universitaria Luis G. Paez',
        },
        {
            value: 'Fundacion Universitaria Maria Cano',
            label: 'Fundacion Universitaria Maria Cano',
        },
        {
            value: 'Fundacion Universitaria Monserrate',
            label: 'Fundacion Universitaria Monserrate',
        },
        {
            value: 'Fundacion Universitaria Navarra',
            label: 'Fundacion Universitaria Navarra',
        },
        {
            value: 'Fundacion Universitaria Para el Desarrollo Humano',
            label: 'Fundacion Universitaria Para el Desarrollo Humano',
        },
        {
            value: 'Fundacion Universitaria Salesiana',
            label: 'Fundacion Universitaria Salesiana',
        },
        {
            value: 'Fundación Universitaria San Alfonso',
            label: 'Fundación Universitaria San Alfonso',
        },
        {
            value: 'Fundación Universitaria San Martín',
            label: 'Fundación Universitaria San Martín',
        },
        {
            value: 'Fundacion Universitaria San Mateo',
            label: 'Fundacion Universitaria San Mateo',
        },
        {
            value: 'Fundacion Universitaria Sanitas',
            label: 'Fundacion Universitaria Sanitas',
        },
        {
            value: 'Fundacion Universitaria Tecnologico Comfenalco - Cartagena',
            label: 'Fundacion Universitaria Tecnologico Comfenalco - Cartagena',
        },
        {
            value: 'Georgia Instiute of Technology',
            label: 'Georgia Instiute of Technology',
        },
        { value: 'Harvard University', label: 'Harvard University' },
        { value: 'Imperial College London', label: 'Imperial College London' },
        {
            value: 'Institución Universitaria Antonio José Camacho',
            label: 'Institución Universitaria Antonio José Camacho',
        },
        {
            value: 'Institución Universitaria Bellas Artes y Ciencias de Bolívar',
            label: 'Institución Universitaria Bellas Artes y Ciencias de Bolívar',
        },
        {
            value: 'Institución Universitaria Colegio Mayor del Cauca',
            label: 'Institución Universitaria Colegio Mayor del Cauca',
        },
        {
            value: 'Institucion Universitaria Colegios de Colombia',
            label: 'Institucion Universitaria Colegios de Colombia',
        },
        {
            value: 'Institucion Universitaria Colombo Americana',
            label: 'Institucion Universitaria Colombo Americana',
        },
        {
            value:
                'Institucion Universitaria de Colombia - Universitaria de Colombia',
            label:
                'Institucion Universitaria de Colombia - Universitaria de Colombia',
        },
        {
            value: 'Institución Universitaria de Envigado',
            label: 'Institución Universitaria de Envigado',
        },
        {
            value: 'Institucion Universitaria Eam',
            label: 'Institucion Universitaria Eam',
        },
        {
            value: 'Institución Universitaria Esumer',
            label: 'Institución Universitaria Esumer',
        },
        {
            value: 'Institución Universitaria ITSA',
            label: 'Institución Universitaria ITSA',
        },
        {
            value: 'Institucion Universitaria Latina',
            label: 'Institucion Universitaria Latina',
        },
        {
            value: 'Institucion Universitaria Marco Fidel Suarez',
            label: 'Institucion Universitaria Marco Fidel Suarez',
        },
        {
            value: 'Institucion Universitaria Salazar y Herrera',
            label: 'Institucion Universitaria Salazar y Herrera',
        },
        { value: 'Instituto Caro y Cuervo', label: 'Instituto Caro y Cuervo' },
        {
            value: 'Instituto Departamental de Bellas Artes',
            label: 'Instituto Departamental de Bellas Artes',
        },
        {
            value: 'Instituto Tecnológico Metropolitano',
            label: 'Instituto Tecnológico Metropolitano',
        },
        {
            value: 'Instituto Tecnológico Pascual Bravo',
            label: 'Instituto Tecnológico Pascual Bravo',
        },
        {
            value: 'Instituto Universitario de La Paz',
            label: 'Instituto Universitario de La Paz',
        },
        { value: 'Johns Hopkins University', label: 'Johns Hopkins University' },
        { value: 'King’s College London', label: 'King’s College London' },
        {
            value: 'Korea Advanced Institute of Science & Technology (KAIST)',
            label: 'Korea Advanced Institute of Science & Technology (KAIST)',
        },
        { value: 'Korea University', label: 'Korea University' },
        {
            value: 'KTH Royal Institute of Tecnhnology',
            label: 'KTH Royal Institute of Tecnhnology',
        },
        { value: 'KU Leuven', label: 'KU Leuven' },
        { value: 'Kyoto University', label: 'Kyoto University' },
        {
            value: 'Lomonosov Moscow State University',
            label: 'Lomonosov Moscow State University',
        },
        {
            value: 'Ludwig-Maximillans- Universität München',
            label: 'Ludwig-Maximillans- Universität München',
        },
        { value: 'Lund University', label: 'Lund University' },
        {
            value: 'Massachusetts Institute of Technology (MIT)',
            label: 'Massachusetts Institute of Technology (MIT)',
        },
        { value: 'McGill University', label: 'McGill University' },
        { value: 'Monash University', label: 'Monash University' },
        {
            value: 'Nanyang Technological University, Singapore (NTU)',
            label: 'Nanyang Technological University, Singapore (NTU)',
        },
        {
            value: 'National Taiwan University (NTU)',
            label: 'National Taiwan University (NTU)',
        },
        {
            value: 'National University of Singapore (NUS)',
            label: 'National University of Singapore (NUS)',
        },
        {
            value: 'New York University (NYU)',
            label: 'New York University (NYU)',
        },
        { value: 'Northwestern University', label: 'Northwestern University' },
        { value: 'Osaka University', label: 'Osaka University' },
        { value: 'Peking University', label: 'Peking University' },
        {
            value: 'Pennsylvania State University',
            label: 'Pennsylvania State University',
        },
        {
            value: 'Pohang University of Science and Technology (POSTECH)',
            label: 'Pohang University of Science and Technology (POSTECH)',
        },
        {
            value: 'Politécnico Colombiano Jaime Isaza Cadavid',
            label: 'Politécnico Colombiano Jaime Isaza Cadavid',
        },
        {
            value: 'Politécnico Grancolombiano',
            label: 'Politécnico Grancolombiano',
        },
        {
            value: 'Pontificia Universidad Javeriana',
            label: 'Pontificia Universidad Javeriana',
        },
        { value: 'Princeton University', label: 'Princeton University' },
        {
            value: 'Ruprecht-Karls-Universität-Heidelberg',
            label: 'Ruprecht-Karls-Universität-Heidelberg',
        },
        {
            value: 'Seoul National University',
            label: 'Seoul National University',
        },
        {
            value: 'Shanghai Jiao Tong University',
            label: 'Shanghai Jiao Tong University',
        },
        { value: 'Sheijang University', label: 'Sheijang University' },
        { value: 'Sorbonne Univerisity', label: 'Sorbonne Univerisity' },
        { value: 'Stanford University', label: 'Stanford University' },
        {
            value: 'Sungkyunkaw University (SKKU)',
            label: 'Sungkyunkaw University (SKKU)',
        },
        {
            value: 'Technical University of Munich',
            label: 'Technical University of Munich',
        },
        { value: 'Tecnológico de Antioquia', label: 'Tecnológico de Antioquia' },
        {
            value: 'The Australian National University',
            label: 'The Australian National University',
        },
        {
            value: 'The Hong Kong Polytechnic University',
            label: 'The Hong Kong Polytechnic University',
        },
        {
            value: 'The Hong Kong University of Science and Technology',
            label: 'The Hong Kong University of Science and Technology',
        },
        {
            value: 'The Londol School of Economics and Political Science (LSE)',
            label: 'The Londol School of Economics and Political Science (LSE)',
        },
        {
            value: 'The Ohio State University',
            label: 'The Ohio State University',
        },
        {
            value: 'The University of Auckland',
            label: 'The University of Auckland',
        },
        {
            value: 'The University of Edinburgh',
            label: 'The University of Edinburgh',
        },
        {
            value: 'The University of Hong Kong',
            label: 'The University of Hong Kong',
        },
        {
            value: 'The University of Manchester',
            label: 'The University of Manchester',
        },
        {
            value: 'The University of Melbourne',
            label: 'The University of Melbourne',
        },
        {
            value: 'The University of New South Wales',
            label: 'The University of New South Wales',
        },
        {
            value: 'The University of Nottingham',
            label: 'The University of Nottingham',
        },
        {
            value: 'The University of Queensland',
            label: 'The University of Queensland',
        },
        { value: 'The University of Tokyo', label: 'The University of Tokyo' },
        {
            value: 'The University of Warwick',
            label: 'The University of Warwick',
        },
        {
            value: 'The Chinese University of Hong Kong (CUHK)',
            label: 'The Chinese University of Hong Kong (CUHK)',
        },
        { value: 'Tohoku University', label: 'Tohoku University' },
        {
            value: 'Tokyo Instiute of Technology (Tokyo Tech)',
            label: 'Tokyo Instiute of Technology (Tokyo Tech)',
        },
        { value: 'Tsinghua University', label: 'Tsinghua University' },
        {
            value: 'UCL (University College London)',
            label: 'UCL (University College London)',
        },
        {
            value: 'Unidad Central del Valle del Cauca',
            label: 'Unidad Central del Valle del Cauca',
        },
        {
            value: 'Universidad Antonio Nariño',
            label: 'Universidad Antonio Nariño',
        },
        {
            value: 'Universidad Autónoma de Bucaramanga',
            label: 'Universidad Autónoma de Bucaramanga',
        },
        {
            value: 'Universidad Autónoma de Colombia',
            label: 'Universidad Autónoma de Colombia',
        },
        {
            value: 'Universidad Autónoma de Manizales',
            label: 'Universidad Autónoma de Manizales',
        },
        {
            value: 'Universidad Autónoma de Occidente',
            label: 'Universidad Autónoma de Occidente',
        },
        {
            value: 'Universidad Autónoma del Caribe',
            label: 'Universidad Autónoma del Caribe',
        },
        {
            value: 'Universidad Autónoma Latinoamericana',
            label: 'Universidad Autónoma Latinoamericana',
        },
        {
            value: 'Universidad Católica de Colombia',
            label: 'Universidad Católica de Colombia',
        },
        {
            value: 'Universidad Católica de Manizales',
            label: 'Universidad Católica de Manizales',
        },
        {
            value: 'Universidad Católica de Oriente',
            label: 'Universidad Católica de Oriente',
        },
        {
            value: 'Universidad Católica de Pereira',
            label: 'Universidad Católica de Pereira',
        },
        {
            value: 'Universidad Católica Luis Amigó',
            label: 'Universidad Católica Luis Amigó',
        },
        { value: 'Universidad Central', label: 'Universidad Central' },
        { value: 'Universidad CES', label: 'Universidad CES' },
        { value: 'Universidad CESMAG', label: 'Universidad CESMAG' },
        {
            value: 'Universidad Colegio Mayor de Cundinamarca',
            label: 'Universidad Colegio Mayor de Cundinamarca',
        },
        {
            value: 'Universidad Cooperativa de Colombia',
            label: 'Universidad Cooperativa de Colombia',
        },
        { value: 'Universidad de América', label: 'Universidad de América' },
        { value: 'Universidad de Antioquía', label: 'Universidad de Antioquía' },
        {
            value: 'Universidad de Bogotá Jorge Tadeo Lozano',
            label: 'Universidad de Bogotá Jorge Tadeo Lozano',
        },
        { value: 'Universidad de Boyacá', label: 'Universidad de Boyacá' },
        {
            value: 'Universidad de Buenos Aires (UBA)',
            label: 'Universidad de Buenos Aires (UBA)',
        },
        { value: 'Universidad de Caldas', label: 'Universidad de Caldas' },
        { value: 'Universidad de Cartagena', label: 'Universidad de Cartagena' },
        {
            value: 'Universidad de Ciencias Aplicadas y Ambientales',
            label: 'Universidad de Ciencias Aplicadas y Ambientales',
        },
        {
            value: 'Universidad de Córdoba, Colombia',
            label: 'Universidad de Córdoba, Colombia',
        },
        {
            value: 'Universidad de Cundinamarca',
            label: 'Universidad de Cundinamarca',
        },
        { value: 'Universidad de Ibagué', label: 'Universidad de Ibagué' },
        {
            value: 'Universidad de la Amazonia',
            label: 'Universidad de la Amazonia',
        },
        {
            value: 'Universidad de La Guajira',
            label: 'Universidad de La Guajira',
        },
        { value: 'Universidad de La Sabana', label: 'Universidad de La Sabana' },
        { value: 'Universidad de La Salle', label: 'Universidad de La Salle' },
        {
            value: 'Universidad de los Andes, Colombia',
            label: 'Universidad de los Andes, Colombia',
        },
        {
            value: 'Universidad de los Llanos',
            label: 'Universidad de los Llanos',
        },
        { value: 'Universidad de Manizales', label: 'Universidad de Manizales' },
        { value: 'Universidad de Medellín', label: 'Universidad de Medellín' },
        { value: 'Universidad de Nariño', label: 'Universidad de Nariño' },
        { value: 'Universidad de Pamplona', label: 'Universidad de Pamplona' },
        {
            value: 'Universidad de San Buenaventura',
            label: 'Universidad de San Buenaventura',
        },
        { value: 'Universidad de Santander', label: 'Universidad de Santander' },
        { value: 'Universidad de Sucre', label: 'Universidad de Sucre' },
        {
            value: 'Universidad del Atlántico',
            label: 'Universidad del Atlántico',
        },
        { value: 'Universidad del Cauca', label: 'Universidad del Cauca' },
        {
            value: 'Universidad del Magdalena',
            label: 'Universidad del Magdalena',
        },
        { value: 'Universidad del Norte', label: 'Universidad del Norte' },
        {
            value: 'Universidad del Pacifico, Colombia',
            label: 'Universidad del Pacifico, Colombia',
        },
        { value: 'Universidad del Quindío', label: 'Universidad del Quindío' },
        { value: 'Universidad del Rosario', label: 'Universidad del Rosario' },
        { value: 'Universidad del Sinú', label: 'Universidad del Sinú' },
        { value: 'Universidad del Tolima', label: 'Universidad del Tolima' },
        { value: 'Universidad del Valle', label: 'Universidad del Valle' },
        {
            value: 'Universidad Distrital Francisco José de Caldas',
            label: 'Universidad Distrital Francisco José de Caldas',
        },
        { value: 'Universidad EAFIT', label: 'Universidad EAFIT' },
        { value: 'Universidad EAN', label: 'Universidad EAN' },
        { value: 'Universidad ECCI', label: 'Universidad ECCI' },
        { value: 'Universidad El Bosque', label: 'Universidad El Bosque' },
        {
            value: 'Universidad Externado de Colombia',
            label: 'Universidad Externado de Colombia',
        },
        {
            value: 'Universidad Francisco de Paula Santander',
            label: 'Universidad Francisco de Paula Santander',
        },
        { value: 'Universidad ICESI', label: 'Universidad ICESI' },
        {
            value: 'Universidad INCCA de Colombia',
            label: 'Universidad INCCA de Colombia',
        },
        {
            value: 'Universidad Industrial de Santander',
            label: 'Universidad Industrial de Santander',
        },
        {
            value: 'Universidad La Gran Colombia',
            label: 'Universidad La Gran Colombia',
        },
        { value: 'Universidad Libre', label: 'Universidad Libre' },
        {
            value: 'Universidad Manuela Beltrán',
            label: 'Universidad Manuela Beltrán',
        },
        { value: 'Universidad Mariana', label: 'Universidad Mariana' },
        {
            value: 'Universidad Metropolitana, Colombia',
            label: 'Universidad Metropolitana, Colombia',
        },
        {
            value: 'Universidad Militar Nueva Granada',
            label: 'Universidad Militar Nueva Granada',
        },
        {
            value: 'Universidad Nacional de Colombia',
            label: 'Universidad Nacional de Colombia',
        },
        {
            value: 'Universidad Pedagógica Nacional',
            label: 'Universidad Pedagógica Nacional',
        },
        {
            value: 'Universidad Pedagógica y Tecnológica de Colombia',
            label: 'Universidad Pedagógica y Tecnológica de Colombia',
        },
        {
            value: 'Universidad Piloto de Colombia',
            label: 'Universidad Piloto de Colombia',
        },
        {
            value: 'Universidad Pontificia Bolivariana',
            label: 'Universidad Pontificia Bolivariana',
        },
        {
            value: 'Universidad Popular del Cesar',
            label: 'Universidad Popular del Cesar',
        },
        {
            value: 'Universidad Santiago de Cali',
            label: 'Universidad Santiago de Cali',
        },
        { value: 'Universidad Santo Tomás', label: 'Universidad Santo Tomás' },
        {
            value: 'Universidad Sergio Arboleda',
            label: 'Universidad Sergio Arboleda',
        },
        {
            value: 'Universidad Simón Bolívar, Colombia',
            label: 'Universidad Simón Bolívar, Colombia',
        },
        {
            value: 'Universidad Surcolombiana',
            label: 'Universidad Surcolombiana',
        },
        {
            value: 'Universidad Tecnológica de Bolívar',
            label: 'Universidad Tecnológica de Bolívar',
        },
        {
            value: 'Universidad Tecnológica de Pereira',
            label: 'Universidad Tecnológica de Pereira',
        },
        {
            value: 'Universidad Tecnológica del Choco',
            label: 'Universidad Tecnológica del Choco',
        },
        {
            value: 'Universitaria Agustiniana',
            label: 'Universitaria Agustiniana',
        },
        { value: 'Université PSL', label: 'Université PSL' },
        { value: 'Universiti Malaya (UM)', label: 'Universiti Malaya (UM)' },
        { value: 'University of Birmigham', label: 'University of Birmigham' },
        { value: 'University of Bristol', label: 'University of Bristol' },
        {
            value: 'University of British Columbia',
            label: 'University of British Columbia',
        },
        {
            value: 'University of California, Berkley (UCB)',
            label: 'University of California, Berkley (UCB)',
        },
        {
            value: 'University of California, Los Angeles (UCLA)',
            label: 'University of California, Los Angeles (UCLA)',
        },
        {
            value: 'University of California, San Diego (UCSD)',
            label: 'University of California, San Diego (UCSD)',
        },
        { value: 'University of Cambridge', label: 'University of Cambridge' },
        { value: 'University of Chicago', label: 'University of Chicago' },
        { value: 'University of Copenhagen', label: 'University of Copenhagen' },
        { value: 'University of Glasgow', label: 'University of Glasgow' },
        {
            value: 'University of Illinois at Urbana-Champaign',
            label: 'University of Illinois at Urbana-Champaign',
        },
        { value: 'University of Leeds', label: 'University of Leeds' },
        { value: 'University of Michigan', label: 'University of Michigan' },
        {
            value: 'University of Norh Carolina, Chapel Hill',
            label: 'University of Norh Carolina, Chapel Hill',
        },
        { value: 'University of Oxford', label: 'University of Oxford' },
        {
            value: 'University of Pennsylvania',
            label: 'University of Pennsylvania',
        },
        {
            value: 'University of Science and Technology of China',
            label: 'University of Science and Technology of China',
        },
        {
            value: 'University of Shielffield',
            label: 'University of Shielffield',
        },
        { value: 'University of Sidney', label: 'University of Sidney' },
        {
            value: 'University of Southampton',
            label: 'University of Southampton',
        },
        { value: 'University of St Andrewa', label: 'University of St Andrewa' },
        {
            value: 'University of Texas at Austin',
            label: 'University of Texas at Austin',
        },
        { value: 'University of Toronto', label: 'University of Toronto' },
        { value: 'University of Washington', label: 'University of Washington' },
        {
            value: 'University of Wisconsin-Madison',
            label: 'University of Wisconsin-Madison',
        },
        { value: 'University of Zurich', label: 'University of Zurich' },
        { value: 'Universiy of Amsterdam', label: 'Universiy of Amsterdam' },
        { value: 'Yale University', label: 'Yale University' },
    ],
    CURATOR_LANGUAGES: [
        { value: 'az', label: 'Azerí' },
        { value: 'af', label: 'Afrikaans' },
        { value: 'sq', label: 'Albanés' },
        { value: 'de', label: 'Alemán' },
        { value: 'als', label: 'Alsaciano' },
        { value: 'am', label: 'Amárico' },
        { value: 'ang', label: 'Anglosajón' },
        { value: 'ar', label: 'Árabe' },
        { value: 'an', label: 'Aragonés' },
        { value: 'hy', label: 'Armenio' },
        { value: 'ast', label: 'Asturiano' },
        { value: 'ay', label: 'Aymara' },
        { value: 'nds', label: 'Bajo sajón' },
        { value: 'bn', label: 'Bengalí' },
        { value: 'be', label: 'Bielorruso' },
        { value: 'my', label: 'Birmano' },
        { value: 'bs', label: 'Bosnio' },
        { value: 'br', label: 'Bretón' },
        { value: 'bg', label: 'Búlgaro' },
        { value: 'kn', label: 'Canarés' },
        { value: 'ca', label: 'Catalán' },
        { value: 'ch', label: 'Chamorro' },
        { value: 'cs', label: 'Checo' },
        { value: 'chr', label: 'Cheroqui' },
        { value: 'zh', label: 'Chino mandarín' },
        { value: 'ko', label: 'Coreano' },
        { value: 'co', label: 'Corso' },
        { value: 'hr', label: 'Croata' },
        { value: 'ku', label: 'Curdo' },
        { value: 'da', label: 'Danés' },
        { value: 'sk', label: 'Eslovaco' },
        { value: 'sl', label: 'Esloveno' },
        { value: 'es', label: 'Español' },
        { value: 'eo', label: 'Esperanto' },
        { value: 'et', label: 'Estonio' },
        { value: 'eu', label: 'Euskera' },
        { value: 'fo', label: 'Feroés' },
        { value: 'fj', label: 'Fiyiano' },
        { value: 'fi', label: 'Finlandés' },
        { value: 'fr', label: 'Francés' },
        { value: 'fy', label: 'Frisón' },
        { value: 'cy', label: 'Galés' },
        { value: 'gl', label: 'Gallego' },
        { value: 'ka', label: 'Georgiano' },
        { value: 'el', label: 'Griego moderno' },
        { value: 'gn', label: 'Guaraní' },
        { value: 'gu', label: 'Gujaratí' },
        { value: 'he', label: 'Hebreo israelí' },
        { value: 'hi', label: 'Hindi' },
        { value: 'nl', label: 'Holandés' },
        { value: 'hu', label: 'Húngaro' },
        { value: 'io', label: 'Ido' },
        { value: 'ig', label: 'Igbo' },
        { value: 'id', label: 'Indonesio' },
        { value: 'en', label: 'Inglés' },
        { value: 'ia', label: 'Interlingua ' },
        { value: 'ga', label: 'Irlandés' },
        { value: 'is', label: 'Islandés' },
        { value: 'it', label: 'Italiano' },
        { value: 'ja', label: 'Japonés' },
        { value: 'jv', label: 'Javanés' },
        { value: 'kk', label: 'Kazako' },
        { value: 'lo', label: 'Laosiano' },
        { value: 'la', label: 'Latín' },
        { value: 'lv', label: 'Letón' },
        { value: 'ln', label: 'Lingala' },
        { value: 'lt', label: 'Lituano' },
        { value: 'lb', label: 'Luxemburgués' },
        { value: 'mk', label: 'Macedonio' },
        { value: 'ml', label: 'Malabar' },
        { value: 'ms', label: 'Malayo' },
        { value: 'mg', label: 'Malgache' },
        { value: 'mt', label: 'Maltés' },
        { value: 'gv', label: 'Manés' },
        { value: 'mi', label: 'Maorí' },
        { value: 'mr', label: 'Marati' },
        { value: 'mo', label: 'Moldavo' },
        { value: 'mn', label: 'Mongol' },
        { value: 'nah', label: 'Náhuatl' },
        { value: 'na', label: 'Nauruano' },
        { value: 'no', label: 'Noruego' },
        { value: 'oc', label: 'Occitano' },
        { value: 'pap', label: 'Papiamento' },
        { value: 'fa', label: 'Persa moderno' },
        { value: 'pl', label: 'Polaco' },
        { value: 'pt', label: 'Portugués' },
        { value: 'pa', label: 'Punjabí' },
        { value: 'qu', label: 'Quechua' },
        { value: 'ky', label: 'Quirguiz' },
        { value: 'rm', label: 'Romanche' },
        { value: 'ro', label: 'Rumano' },
        { value: 'ru', label: 'Ruso' },
        { value: 'sc', label: 'Sardo' },
        { value: 'sr', label: 'Serbio' },
        { value: 'st', label: 'Sesotho' },
        { value: 'scn', label: 'Siciliano' },
        { value: 'so', label: 'Somalí' },
        { value: 'sv', label: 'Sueco' },
        { value: 'sw', label: 'Swahili' },
        { value: 'tl', label: 'Tagalo' },
        { value: 'th', label: 'Tailandés' },
        { value: 'ta', label: 'Tamil' },
        { value: 'tt', label: 'Tártaro' },
        { value: 'crh', label: 'Tártaro de Crimea' },
        { value: 'te', label: 'tegulú' },
        { value: 'bo', label: 'Tibetano' },
        { value: 'tr', label: 'Turco' },
        { value: 'tk', label: 'Turcomano' },
        { value: 'uk', label: 'Ucraniano' },
        { value: 'ur', label: 'Urdu' },
        { value: 'uz', label: 'Uzbeko' },
        { value: 'wa', label: 'Valón' },
        { value: 'vi', label: 'Vietnamita' },
        { value: 'vo', label: 'Volapuk' },
        { value: 'xh', label: 'Xhosa' },
        { value: 'yi', label: 'Yidish' },
        { value: 'yo', label: 'Yoruba' },
        { value: 'zu', label: 'Zulú' },
    ]
};
